import React from "react";
import CardContainer from "../../UI-component/registryForms/CardContainer";
import PreferenceInput from "../../UI-component/preferencesForms/PreferenceInput";
import PreferenceContainer from "../../UI-component/preferencesForms/PreferenceContainer";
import AdvancePayment from "../../UI-component/AdvancePayment";
import Balance from "../../UI-component/Balance";
import TotalPaymentInfo from "../../UI-component/TotalPaymentInfo";
import SavingForm from "../../UI-component/SavingForm";

export default function PagamentiIMUPreferenze(props) {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          {/*TODO: Dynamically creates a form box with the given input and info */}
          <PreferenceContainer>
            <CardContainer title="H501 - Roma">
              <PreferenceInput
                title="Import versato*"
                description="Inserisci la somma gia versata"
              />
              <AdvancePayment
                expiryDate={"16/06/2021"}
                timeLeft={"224 giorni"}
                total="234,00$"
              />
            </CardContainer>
          </PreferenceContainer>

          <PreferenceContainer>
            <CardContainer title="L065 - Tavagnacco">
              <PreferenceInput
                title="Import versato*"
                description="Inserisci la somma gia versata"
              />
              <AdvancePayment
                expiryDate={"16/06/2021"}
                timeLeft={"224 giorni"}
                total="148,67$"
              />
              <Balance
                expiryDate={"16/06/2021"}
                timeLeft={"224 giorni"}
                total="148,67$"
              />
              <TotalPaymentInfo totalToBePaid="900,00$" toBePaid="00,00$" />
            </CardContainer>
          </PreferenceContainer>

          {/* TODO: the saving form replaces the above commnet structure needs saveRequest and save function to make the button work */}
          {/* <SavingForm /> */}
        </div>
      </div>
    </>
  );
}
