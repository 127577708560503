import {  apiAuth } from "./Configs/AxiosConfigs"; 

export const ServiceDetailsAPI = { 
  getServiceDetails: async function (code) {
    const response = await apiAuth.request({
      url: "/service-details/" + code,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })  
    return response.data;
  }



}; 
