import React from "react";
import {useSelector} from "react-redux";
import "../global.css";


function SavingForm(props) {
  const pvtCtx = useSelector(state => state.stepSlice.privacy);

    return (
        <div className="cmp-nav-steps" style={{marginBottom: "75px"}}>
          {
            props.step > 0 && props.step < props.totalSteps &&
              <nav className="steppers-nav" aria-label="Step">
                
                {
                  props.step >= 1 &&
                    <button
                      type="button"
                      className="btn btn-outline-primary bg-white btn-sm steppers-btn-save d-none d-lg-block saveBtn" onClick={props.saveRequest}>
                      <span className="text-button-sm t-primary">Salva Richiesta</span>
                    </button>
                }
                {
                  props.step === props.totalSteps -1 &&
                    <button
                      type="button"
                      className={`btn btn-primary btn-sm steppers-btn-confirm send ${!pvtCtx ? "disabled-btn-pvt" : ""}`}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-terms" onClick={props.send}>
                      <span className="text-button-sm">Invia</span>
                    </button>
                }
              </nav>
          }
          {
            props.step === "undefined" &&
              <nav className="steppers-nav" aria-label="Step">
                <button
                  type="button"
                  className="btn btn-outline-primary bg-white btn-sm steppers-btn-save d-none d-lg-block saveBtn" onClick={props.saveRequest}>
                  <span className="text-button-sm t-primary">Salva Richiesta</span>
                </button>
                <button
                  type="button"
                  className={`btn btn-primary btn-sm steppers-btn-confirm send ${!pvtCtx ? "disabled-btn-pvt" : ""}`}
                  data-bs-toggle="modal"
                  data-bs-target="#modal-terms" onClick={props.send}>
                  <span className="text-button-sm">Invia</span>
                </button>
              </nav>
          }
            <div
              id="alert-message"
              className="alert alert-success cmp-disclaimer rounded d-none"
              role="alert">
              <span className="d-inline-block text-uppercase cmp-disclaimer__message">
                Richiesta salvata con successo
              </span>
            </div>
          </div>
    )
}

export default SavingForm;