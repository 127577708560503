import React from "react";
import SideList from "../../UI-component/SideList";
import SavingForm from "../../UI-component/SavingForm";
import SelectionFormBox from "../../UI-component/SelectionFormBox";

//OFFICE SELECTOR
function First(props) {
    return (
        <React.Fragment>
            <SideList infoList={[{element: "Ufficcio", id: "#office" }]}/>

            <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <div className="steppers-content" aria-live="polite">
                  <div className="it-page-sections-container">
                    
                    {/* TODO: Selection box created dynamially using api data */}

                    <SelectionFormBox
                        title="Ufficio"
                        description="Scegli l'ufficio a cui vuoi richiedere l'appuntamento"
                        selectTitle="Seleziona l'ufficio"
                        choices={[
                            { "id":"1" ,"title" : "Giunta Comunale"},
                            { "id":"2" ,"title" : "Consiglio Comunale"},
                            { "id":"3" ,"title" : "Ufficio SUAP"},
                            { "id":"1" ,"title" : "Ufficio Edilizia Privata e Lavori Pubblici"},
                            { "id":"2" ,"title" : "Ufficio Manutenzione Ordinaria"},
                            { "id":"3" ,"title" : "Area Tecnica"},
                            { "id":"1" ,"title" : "Ufficio Contratti"},
                            { "id":"2" ,"title" : "Ufficio Segreteria"},
                            { "id":"3" ,"title" : "Ufficio Tributi"},
                            { "id":"1" ,"title" : "Area Finanziaria"},
                            { "id":"2" ,"title" : "Ufficio Anagrafe ed Elettorale"},
                            { "id":"3" ,"title" : "Ufficio Relazioni con il Pubblico (URP)"},
                            { "id":"3" ,"title" : "Area Amministrativa"},
                        ]}
                    />
                  </div>
                  {/* <SavingForm/> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default First;