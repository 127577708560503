import React from "react";


function PositionButton() {
    return (
        <div class="link-wrapper mt-3">
            <a class="list-item active icon-left" href="#" data-focus-mouse="false">
                <span class="list-item-title-icon-wrapper">
                    <svg class="icon icon-sm icon-primary mb-1" aria-hidden="true">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-map-marker"></use>
                    </svg>
                    <span class="list-item-title t-primary">Usa la tua posizione</span>
                </span>
            </a>
            </div>
    )
}

export default PositionButton;