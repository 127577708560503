
function ShareLinkHeader() {
    return (
        <div className="it-right-zone">
            <div className="it-socials d-none d-lg-flex">
            <span>Seguici su</span>
            <ul>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-twitter"></use>
                    </svg>
                    <span className="visually-hidden">Twitter</span>
                </a>
                </li>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-facebook"></use>
                    </svg>
                    <span className="visually-hidden">Facebook</span>
                </a>
                </li>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-youtube"></use>
                    </svg>
                    <span className="visually-hidden">YouTube</span>
                </a>
                </li>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-telegram"></use>
                    </svg>
                    <span className="visually-hidden">Telegram</span>
                </a>
                </li>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-whatsapp"></use>
                    </svg>
                    <span className="visually-hidden">Whatsapp</span>
                </a>
                </li>
                <li>
                <a href="#" target="_blank">
                    <svg className="icon icon-sm icon-white align-top">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-rss"></use>
                    </svg>
                    <span className="visually-hidden">RSS</span>
                </a>
                </li>
            </ul>
            </div>
            <div className="it-search-wrapper">
            <span className="d-none d-md-block">Cerca</span>
            <button
                className="search-link rounded-icon"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#search-modal"
                aria-label="Cerca nel sito"
            >
                <svg className="icon">
                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-search"></use>
                </svg>
            </button>
            </div>
      </div>
    )
}

export default ShareLinkHeader;