import React from "react";
import IseeCard from "../registryForms/IseeCard";


function IseeFormBox(props) {
    return ( 
       <>
            <section className="it-page-section" id={props.id}>
                <div className="cmp-card mb-40">
                

                    <div className="card has-bkg-grey shadow-sm p-big">
                        {/* <PropertyCardHeader/> */}

                        <div className="card-header border-0 p-0 mb-lg-30 m-0">
                            <div className="d-flex">
                                <h2 className="title-xxlarge mb-1">ISEE {props.isee}</h2>
                            </div>
                            <p className="subtitle-small mb-0">Informazioni sul valore dell’ISEE del tuo nucleo familiare basato sulla tua ultima dichiarazione</p>
                            <br></br>
                        </div>

                        <div className="card-body p-0">
                            {
                                <IseeCard id={props.id} info={{cards: props.cards}} key={Math.random() * 31}/>
                            }
                        </div>
                    </div> 

                    
                </div>
            </section>
       </>
  )
}

export default IseeFormBox;