

function PreferenceHeader(props) {
    return (
        <div className="card-header">
            <div className="form-check form-check-group mt-0 p-0">
            <input className="check-input" id={1} type="checkbox" />
            <label htmlFor={1}>
                <h2 className="title-xlarge u-main-black">{props.title}</h2>
            </label>
            </div>
        </div>
    )
}


export default PreferenceHeader;

