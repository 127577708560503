import React from "react";
import SideList from "../../UI-component/SideList";
import SavingForm from "../../UI-component/SavingForm";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import AreaFormBox from "../../UI-component/AreaFormBox";

//OFFICE SELECTOR
function Third(props) {
    return (
        <React.Fragment>
            <SideList infoList={[{element: "Motivo", id: "#motive" },{element: "Dettagli", id: "#details" }]}/>
            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <div className="cmp-card mb-40" id="reason">
                    {/* TODO: selection card created dynamially using api data */}

                    <SelectionFormBox
                        title="Motivo"
                        description="Scegli il motivo dell'appuntamento"
                        selectTitle="Seleziona il motivo"
                        choices={[]}
                        />
                    <AreaFormBox title="Dettagli" description="Aggiungi ulteriori dettagli"/>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Third;