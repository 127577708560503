import React from "react";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import SavingForm from "../../UI-component/SavingForm";

function PagamentiIMUAnteprimaF24(props) {
  // {
  //   "Contribuenti": {
  //    [ {},{}]
  //   },
  //   "Cobbligato":
  //   [ {},{}]
  // }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 d-lg-none mb-40 mt-4">
          <p className="text-paragraph">
            Rivedi le informazioni da inserire nel documento F24. Conferma e
            continua per scaricare il documento: lo troverai sempre disponibile
            nella tua area personale.
          </p>
        </div>
        <div className="col-12 col-lg-8">
          {/* TODO: Summary card created dynamially using api data */}
          <SummaryCard mod={true} cardTitle="Contribuente" type="Motivo Del Pagamento">
            <FormBoxField title="Nome" value="Giulia" />
            <FormBoxField title="Cognome" value="Rossi" />
            <FormBoxField title="Data Di Nascita" value="02/03/1975" />
            <FormBoxField
              title="Domicilio Fiscale"
              value="Via Roma 16, 00100 Roma, It"
            />
          </SummaryCard>

          <SummaryCard mod={true} cardTitle="H501-Roma">
            <FormBoxField title="Ravvedimento" value="Si" />
            <FormBoxField title="Imm. var.:" value="No" />
            <FormBoxField title="Acconto" value="Si" />
            <FormBoxField title="Saldo" value="No" />
            <FormBoxField title="Rateaz/ mese rif:" value="01" />
            <FormBoxField title="Anno di rif:" value=" 2022" />
            <FormBoxField title="Detrazione:" value="100,00 €" />
            <FormBoxField title="Importi a debito versati:" value="500,00 €" />
            <FormBoxField
              title="Importi a credito compensati:"
              value="700,00 €"
            />
          </SummaryCard>

          <SummaryCard mod={true} cardTitle="H501-Roma">
            <FormBoxField title="Ravvedimento" value="Si" />
            <FormBoxField title="Imm. var.:" value="No" />
            <FormBoxField title="Acconto" value="Si" />
            <FormBoxField title="Saldo" value="No" />
            <FormBoxField title="Rateaz/ mese rif:" value="01" />
            <FormBoxField title="Anno di rif:" value=" 2022" />
            <FormBoxField title="Detrazione:" value="-- €" />
            <FormBoxField title="Importi a debito versati:" value="-- €" />
            <FormBoxField
              title="Importi a credito compensati:"
              value="200,00 €"
            />
          </SummaryCard>

          <SummaryCard mod={true} cardTitle="L736-Venezia">
            <FormBoxField title="Ravvedimento" value="Si" />
            <FormBoxField title="Imm. var.:" value="No" />
            <FormBoxField title="Acconto" value="Si" />
            <FormBoxField title="Saldo" value="No" />
            <FormBoxField title="Rateaz/ mese rif:" value="01" />
            <FormBoxField title="Anno di rif:" value=" 2022" />
            <FormBoxField title="Detrazione:" value="-- €" />
            <FormBoxField title="Importi a debito versati:" value="-- €" />
            <FormBoxField
              title="Importi a credito compensati:"
              value="200,00 €"
            />
          </SummaryCard>

          <SummaryCard mod={true} cardTitle="L065-Tavagnacco">
            <FormBoxField title="Ravvedimento" value="Si" />
            <FormBoxField title="Imm. var.:" value="No" />
            <FormBoxField title="Acconto" value="Si" />
            <FormBoxField title="Saldo" value="No" />
            <FormBoxField title="Rateaz/ mese rif:" value="01" />
            <FormBoxField title="Anno di rif:" value=" 2022" />
            <FormBoxField title="Detrazione:" value="-- €" />
            <FormBoxField title="Importi a debito versati:" value="-- €" />
            <FormBoxField
              title="Importi a credito compensati:"
              value="900,00 €"
            />
          </SummaryCard>

          {/* TODO: need save and send events */}
          {/* <SavingForm /> */}
        </div>
      </div>
    </>
  );
}

export default PagamentiIMUAnteprimaF24;
