import React, { useState } from "react";


function CompletitionBox(props) {

    const [isOpen,setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(prev => !prev);
    }

    //name,state,date,payCode,payMethod,amount,serviceLink,nameLinlk, link,nameLink,receipt
    return (
        <div className="accordion-item" onClick={toggleOpen}>
            {
                isOpen ?
                    <>
                        <div className="accordion-header" id="heading2">
                        <button className="accordion-button collapsed title-snall-semi-bold" type="button">
                            <div className="button-wrapper">
                            {props.doc.name}
                            <div className="icon-wrapper">
                                <img className="icon-folder" src="../assets/images/folder-concluded.svg" alt="folder Conclusa"/>
                                <span className="">{props.doc.state}</span>
                            </div>
                            </div>
                        </button>
                        <p className="accordion-date title-xsmall-regular mb-0">
                            {props.doc.date}
                        </p>
                        </div>
                    <div>
                        <div className="accordion-body">

                        <p className="mb-2 fw-normal">
                            Pagamento:
                            <span className="label">{props.doc.paymentCode}</span>
                        </p>
                        <p className="mb-2 fw-normal">
                            Metodo: <span className="label">{props.doc.paymentMethod}</span>
                        </p>
                        <p className="mb-2 fw-normal">
                            Importo: <span className="label">{props.doc.amount}</span>
                        </p>
                        <a href={props.doc.link} className="mb-2">
                            <span className="t-primary">
                            {props.doc.linkName}
                            </span>
                        </a>
                        <a className="chip chip-simple" href={props.doc.serviceLink}>
                            <span className="chip-label">
                            {props.doc.serviceNameLink}
                            </span>
                        </a>
                        <div className="cmp-icon-list">
                            <div className="link-list-wrapper">
                            <ul className="link-list">
                                <li className="shadow p-0 p-0">
                                <a
                                    className="list-item icon-left t-primary title-small-semi-bold"
                                    href="#"
                                    aria-label="Scarica la Ricevuta richiesta"
                                >
                                    <span className="list-item-title-icon-wrapper">
                                    <svg
                                        className="icon icon-sm align-self-start icon-color"
                                        aria-hidden="true"
                                    >
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                    </svg>
                                    <span className="list-item-title title-small-semi-bold">
                                        {props.doc.receipt}
                                    </span>
                                    </span>
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div> 
                    </>
                    :
                        <div className="accordion-header" id="heading2">
                        <button className="accordion-button collapsed title-snall-semi-bold" type="button">
                            <div className="button-wrapper">
                            {props.doc.name}
                            <div className="icon-wrapper">
                                <img className="icon-folder" src="../assets/images/folder-concluded.svg" alt="folder Conclusa"/>
                                <span className="">{props.doc.state}</span>
                            </div>
                            </div>
                        </button>
                        <p className="accordion-date title-xsmall-regular mb-0">
                            {props.doc.date}
                        </p>
                        </div>
            }
        </div>
        
    )
}

export default CompletitionBox;