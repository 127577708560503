import React from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useSelector } from "react-redux";

export default function ServiziPagamentoRiepilogo(props) {
  const data = useSelector(state => state.stepSlice.data);
  
  // renders customer preferences summary
  //based on the data that resides in the ctx
  const preferencesRenderer = () => {
    return (
      <SummaryCard mod={true} cardTitle="Preferenze di Servizio" type="Preferenze" key={Math.random() * 31 * Math.random() * 11}>
        {
          Object.keys(data.preferences).map(el => {
            if(Array.isArray(data.preferences[el])){
              return data.preferences[el].map(itm => {
                return <FormBoxField title={el.replace("_", " ")} value={itm} key={Math.random() * 31 * Math.random() * 11}/>
              })
            }else 
              return <FormBoxField title={el.replace("_", " ")} value={data.preferences[el] ? data.preferences[el] : "Non presente" } key={Math.random() * 31 * Math.random() * 11}/>
          })
        }
      </SummaryCard>
    )
  } 
  
  // renders customer summary (isee, applicant,beneficiary)
  //based on the data that resides in the ctx
  const customerRenderer = (key) => {
    let title = "";
    if(key.toLowerCase() === "beneficiary")
      title = "Beneficiario";
    else if(key.toLowerCase() === "applicant")
      title = "Richiedente";
    else 
      title = "ISEE";
    
    return (
      <>
          {
            data[key] !== undefined &&
              data[key].map(itm => {
                if(itm?.selected) {
                return (
                  <SummaryCard taxID={itm?.reg?.CodiceFiscale} mod={true} cardTitle="Preferenze di Servizio" type={title} key={Math.random() * 31 * Math.random() * 11}>
                    {
                      Object.keys(itm).map(el => {
                        if(key === "isee") {
                          return <FormBoxField title={el} value={itm[el] ? itm[el] :  "Non Presente" }
                             key={Math.random() * 31 * Math.random() * 11}/>
                        } else {
                            return Object.keys(itm[el]).map(internalItm => {
                              return <FormBoxField title={internalItm} 
                                  value={itm[el][internalItm] ? itm[el][internalItm] :  "Non Presente" } key={Math.random() * 31 * Math.random() * 11}/>
                            })
                        }
                      })
                    }
                  </SummaryCard>
                  )
                }
              }) 
          }
      </>
    )
  } 

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        {
          Object.keys(data).map(key => {
            if(data[key] !== undefined || data[key] !== []) {
              if(key === "beneficiary" || key === "isee" || key === "applicant") 
                return customerRenderer(key);
              else if (key === "preferences"){
                return preferencesRenderer("Preferenze");
              }
            }
          }) 
        }

        {/* TODO aggiungere tipologie al summaryCard cosi che il modifica funmzioni anche per questi */}        
      </div>
    </div>
  );
}
