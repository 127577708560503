import React from "react";


function WorkPath(props) {
  return (
      <div className="container" id="main-container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="cmp-breadcrumbs " role="navigation">
            <nav className="breadcrumb-container" aria-label="breadcrumb">
              <ol
                className="breadcrumb p-0"
                data-element="breadcrumb"
                itemScope
                itemType="http://schema.org/BreadcrumbList"
              >
                <meta name="numberOfItems" content={3} />
                <meta name="itemListOrder" content="Ascending" />
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  className="breadcrumb-item trail-begin"
                >
                  <a
                    href={props.workPathInfo.base[1]}
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">{props.workPathInfo.base[0]}</span>
                  </a>
                  <meta itemProp="position" content={1} />
                </li>
                
                {
                  props.workPathInfo.medium &&
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="http://schema.org/ListItem"
                      className="breadcrumb-item"
                    >
                      <span className="separator">/</span>
                      <a
                        href={props.workPathInfo.medium[1]}
                        itemProp="item"
                      >
                        <span itemProp="name">{props.workPathInfo.medium[0]}</span>
                      </a>
                      <meta itemProp="position" content={2} />
                    </li>
                }

                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  className="breadcrumb-item active"
                >
                  <span className="separator">/</span>
                  <span itemProp="item">
                    <span itemProp="name">{props.workPathInfo.end}</span>
                  </span>
                  <meta itemProp="position" content={3} />
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}


export default WorkPath;