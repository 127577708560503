import React from "react";

import PropertyRegistry from "../registryForms/PopertyRegistry";

function PropertyCard(props) {
    //name,taxid
    return ( 
        <div className="cmp-info-button-card radio-card">
            <div className="card p-3 p-lg-4">
                <div className="card-header mb-0 p-0">
                <div className="form-check m-0">
                    <input className="radio-input" name="beneficiaries" type="radio" id="first" />
                    <label htmlFor="first">
                        <h3 className="big-title mb-0">{props.info.name}</h3>
                    </label>
                </div>
                </div>
                <div className="card-body p-0">
                    <p className="card-info">Tipologia <br /> <span>{props.info.type}</span></p>
                    <PropertyRegistry/>
                </div>
            </div>
        </div>
  )
}

export default PropertyCard;