import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import HelpBox from "../../UI-component/HelpBopx";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import CompactInfo from "../../UI-component/CompactInfo";
import DatiSegnalazione from "../_Partials/DatiSegnalazione";

export default function SegnalazioneDisservizio(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  
  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);
 
  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}> 
      <main>
        <div className="container" id="main-container">
          <div className="row justify-content-center">
            <WorkPath workPathInfo={{
              base: ["Home","https://stellanello.nemea.cloud/"],
              medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
              end: "Segnalazione Disservizio"
            }}/>

            <div className="col-12 col-lg-10">
              {/* header and page description */}
              <CompactInfo title={"Segnalazione Disservizio"} description={`
                  Inserisci le informazioni necessarie per pagare l’IMU.<br />
                  Potrai ricontrollare tutti i dati nel riepilogo, prima di
                  inviare la richiesta.`}/>
            </div>
          </div>
          
          <DynamicStepContent stepList={["INFORMATIVA SULLA PRIVACY", "DATI DI SEGNALAZIONE", "RIEPILOGO"]} steps={[
            {component:Privacy, info: header?.description},
            {component:DatiSegnalazione,info: user},
          ]}/>

     
        </div>
   
        <HelpBox helpBoxInfo={{
            muniQuestionLink: "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "05 0505",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: ""
          }}/>
      </main>
    </AuthenticatedTenant>
  );
}


