import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import HelpBox from "../../UI-component/HelpBopx";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import First from "../_Partials/First";
import Second from "../_Partials/Second";
import Third from "../_Partials/Third";
import Fourth from "../_Partials/Fourth";
import Fifth from "../_Partials/Fifth";
import Sixth from "../_Partials/Sixth";

export default function PrenotaAppuntamento(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
 
  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
          end: "Prenotazioni"
        }}/>

        <div id="form-steps">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <div className="cmp-hero">
                  <section className="it-hero-wrapper bg-white align-items-start">
                    <div className="it-hero-text-wrapper pt-0 ps-0 pb-3 pb-lg-4">
                      <h1
                        className="text-black hero-title"
                        data-element="page-name"
                      >
                        Prenotazione appuntamento
                      </h1>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <DynamicStepContent stepList={["Luogo", "Data e Orario","Dettagli Appuntamento","Richiedente","Riepilogo"]} steps={[
                {component: First,info: {}},
                {component: Second,info: {}},
                {component: Third,info: {}},
                {component: Fourth,info: {}},
                {component: Fifth,info: {}},
                {component: Sixth,info: {}},
              ]}/>
            </div>
          </div>
        
        </div>
        <HelpBox helpBoxInfo={{
            muniQuestionLink: "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "tel:+39 0182 668000",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: ""
          }}/>
      </main>
    </AuthenticatedTenant>
  );
}
