import React from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";


function ContactEditPopup(props) {
    return (
        <PopupBox close={props.close} title="Contatti"> 
            <PopupTextField fieldType="text" fieldTitle="Telefono"/>
            <PopupTextField fieldType="email" fieldTitle="Email"/>
            <PopupTextField fieldType="text" fieldTitle="Recapito Postale"/>
        </PopupBox>
    )
}

export default ContactEditPopup;