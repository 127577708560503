import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";


function IseeEditPopup(props) {
    const dispatcher = useDispatch();
    const iseeFormData = useSelector(state => state.stepSlice.data.isee);

    const [iseeData,setIseeData] = useState({
        "valoreIsee": "",
        "scadenzaIsee": "",
        "annoProtocollo": "",
        "numeroProtocollo": "",
        "codiceFilialeProtocollo": "",
        "certificatoIsee": "",
        "selected": true
    });

    useEffect(() => {
        for(let i of iseeFormData) {
            setIseeData(i);
            break;
        }
    },[])

    const onDataChange = (e) => {
        const id = e.target.id.toLowerCase().replace(" ","").trim();
        setIseeData(prev => {
            let modified = {...prev};

            Object.keys(modified).map(key => {
                console.log(modified['valoreIsee']);
                if(id === key.toLocaleLowerCase())
                    modified[key] = e.target.value;
            })
            return modified;
        })
    }

    const save = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: props.id,data: iseeData}));
    }

    return (
        <PopupBox close={props.close} save={save} title="ISEE"> 
            <PopupTextField defaultValue={iseeData.scadenzaIsee} onChange={onDataChange} fieldType="date" fieldTitle="Scadenza ISEE"/>
            <PopupTextField defaultValue={iseeData.valoreIsee} onChange={onDataChange} fieldType="text" fieldTitle="Valore ISEE"/>
            <PopupTextField defaultValue={iseeData.annoProtocollo} onChange={onDataChange} fieldType="text" fieldTitle="Anno Protocollo"/>
            <PopupTextField defaultValue={iseeData.numeroProtocollo} onChange={onDataChange} fieldType="text" fieldTitle="Numero Protocollo"/>
            <PopupTextField defaultValue={iseeData.codiceFilialeProtocollo} onChange={onDataChange} fieldType="text" fieldTitle="Codice Filiale Protocollo"/>
            <PopupTextField defaultValue={iseeData.certificatoIsee} onChange={onDataChange} fieldType="file" fieldTitle="Certificato ISEE"/>
        </PopupBox>
    )
}

export default IseeEditPopup;