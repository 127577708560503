import {  apiAuth } from "./Configs/AxiosConfigs";
import { defineCancelApiObject } from "./Configs/AxiosUtils";

export const ServiceRequestAPI = {
  sendServiceRequest: async function (formDataJSON, cancel = false) {
    const response = await apiAuth.request({
      url: "/service-request/",
      method: "POST",
      data: formDataJSON,
      signal: cancel
        ? cancelApiObject[this.sendServiceRequest.name].handleRequestCancellation()
            .signal
        : undefined,
    });  
    return response.data;
  },
  getRequestByUser: async function (cancel = false) {
    const response = await apiAuth.request({
      url: "/service-request-by-user/",
      method: "GET",
      signal: cancel ? cancelApiObject[this.getRequestByUser.name].handleRequestCancellation().signal : undefined,
    })  
    return response.data;
  }



};

// defining the cancel API object for ServiceRequestAPI
const cancelApiObject = defineCancelApiObject(ServiceRequestAPI);
