import React from "react";

function PopupSelectField(props) {
    return (
        <div className="select-wrapper mt-30 mb-40 bg-transparent p-0">
            <label for={props.id} className="" mb-3="">{props.fieldTitle}*</label>
            {
                props.multi ? 
                    <select onChange={props.onChange} multiple id={props.id} className="bg-transparent long" required="" data-dashlane-rid="49781564a800cfad" data-form-type="other" style={{cursor: "pointer"}}>
                        <option selected="selected" value="" disabled>Seleziona opzione</option>
                        {
                            props.options.map((option) => {
                                return <option value={option}>{option}</option>
                            })
                        }
                    </select> :
                    <select onChange={props.onChange} id={props.id} className="bg-transparent" required="" data-dashlane-rid="49781564a800cfad" data-form-type="other" style={{cursor: "pointer"}}>
                        <option selected="selected" value="" disabled>Seleziona opzione</option>
                        {
                            props.options.map((option) => {
                                return <option value={option}>{option}</option>
                            })
                        }
                   </select>
            }
         
            <div className="d-flex mb-30">
                <span className="form-text cmp-input__text">
                {props.description ? props.description : "Inserire l’informazione per proseguire con la richiesta" }</span>
            </div>
        </div>
    )
}

export default PopupSelectField;