
function PreferenceInput(props) {
    return (
        <div className="text-area-wrapper pt-25 pb-25 pl-25 pr-25 pt-lg-40 pb-lg-40 pl-lg-40 pr-lg-40 bg-white">
            <div className="form-group cmp-input mb-0 border-bold">
                <label className="cmp-input__label" htmlFor="amount-1">{props.title}</label>
                <input type="text" className="form-control" id="amount-1" name="amount-1" required />
                <div className="d-flex">
                <span className="form-text cmp-input__text">
                    {props.description}</span>
                </div>
            </div>
        </div>

    )
}

export default PreferenceInput;