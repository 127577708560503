import React from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";

// Popup that gets created to edit that specific form box

function AddressesEditPopup(props) {
    return (
        <PopupBox close={props.close} title="Indirizzo"> 
            <PopupTextField fieldType="text" fieldTitle="Residenza"/>
            <PopupTextField fieldType="text" fieldTitle="Domicilio"/>
        </PopupBox>
    )
}

export default AddressesEditPopup;