import React from "react";
import SideList from "../../UI-component/SideList";

//OFFICE SELECTOR
function Sixth(props) {
    return (
        <React.Fragment>
            <SideList infoList={[
                {element: "Cosa serve", id: "#needed" },
                {element: "Indirizzo", id: "#address" },
                {element: "Aggiungi al tuo calendario", id: "#add" }]}/>

            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="cmp-hero">
                        <section className="it-hero-wrapper bg-white align-items-start">
                            <div className="it-hero-text-wrapper pt-0 ps-0 pb-40">
                            <div className="categoryicon-top d-flex">
                                <svg
                                className="icon icon-success mr-10 icon-sm mb-1"
                                aria-hidden="true"
                                >
                                <use href="#it-check-circle" />
                                </svg>
                                <h1
                                className="text-black hero-title"
                                data-element="page-name"
                                >
                                Appuntamento confermato
                                </h1>
                            </div>
                            <p className="titillium hero-text">
                                L’appuntamento è fissato per
                                <strong id="date-recap" />
                                <br />
                                <br />
                                Abbiamo inviato il riepilogo all’email:
                                <br />
                                <strong id="email-recap" />
                            </p>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                    <hr className="d-none d-lg-block mb-40" />
                </div>
                <div className="container">
                    <div className="row mb-4 mt-lg-80">
                    <div className="col-12 col-lg-8 offset-lg-1">
                        <div className="cmp-card mb-50">
                        <div className="card has-bkg-primary-grey p-3">
                            <div className="card-header border-0 p-0 mb-lg-30">
                            <div className="d-flex">
                                <h2 className="title-xxlarge mb-0" id="needed">
                                Cosa serve
                                </h2>
                            </div>
                            </div>
                            <div className="card-body p-0">
                            <div className="cmp-ul-list" id="needed-recap" />
                            </div>
                        </div>
                        </div>
                        <div className="address-wrapper mb-50">
                        <h2 className="title-xxlarge mb-10" id="address">
                            Indirizzo
                        </h2>
                        <div className="cmp-card-img row mx-auto">
                            <div className="col-11 col-lg-6 px-0">
                            <div className="card card-teaser card-teaser-info rounded shadow-sm">
                                <div className="card-body align-items-center card-body d-flex justify-content-between">
                                <div className="left">
                                    <span className="title-small" id="office-recap" />
                                    <p
                                    className="subtitle-small mb-0"
                                    id="address-recap"
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* links */}
                        <div className="cmp-icon-list">
                        <h2
                            className="title-xxlarge mt-40 mb-2 mb-lg-4"
                            id="calendar"
                        >
                            Aggiungi al tuo calendario
                        </h2>
                        <div className="link-list-wrapper">
                            <ul className="link-list mt-3">
                            <li className>
                                <a
                                className="list-item icon-left t-primary title-small-semi-bold"
                                href="#"
                                >
                                <span className="list-item-title-icon-wrapper">
                                    <svg
                                    className="icon icon-sm align-self-start icon-primary icon-color"
                                    aria-hidden="true"
                                    >
                                    <use href="#it-calendar" />
                                    </svg>
                                    <span className="list-item-title title-small-semi-bold fw-normal">
                                    Outlook
                                    </span>
                                </span>
                                </a>
                            </li>
                            <li className>
                                <a
                                className="list-item icon-left t-primary title-small-semi-bold"
                                href="#"
                                >
                                <span className="list-item-title-icon-wrapper">
                                    <svg
                                    className="icon icon-sm align-self-start icon-primary icon-color"
                                    aria-hidden="true"
                                    >
                                    <use href="#it-calendar" />
                                    </svg>
                                    <span className="list-item-title title-small-semi-bold fw-normal">
                                    Google Calendar
                                    </span>
                                </span>
                                </a>
                            </li>
                            <li className>
                                <a
                                className="list-item icon-left t-primary title-small-semi-bold"
                                href="#"
                                >
                                <span className="list-item-title-icon-wrapper">
                                    <svg
                                    className="icon icon-sm align-self-start icon-primary icon-color"
                                    aria-hidden="true"
                                    >
                                    <use href="#it-calendar" />
                                    </svg>
                                    <span className="list-item-title title-small-semi-bold fw-normal">
                                    iCloud Calendar
                                    </span>
                                </span>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="bg-primary">
                    <div className="container">
                    <div className="row d-flex justify-content-center bg-primary">
                        <div className="col-12 col-lg-6 p-lg-0 px-3">
                        <div className="cmp-rating pt-lg-80 pb-lg-80" id="rating">
                            <div
                            className="card shadow card-wrapper"
                            data-element="feedback"
                            >
                            <div className="cmp-rating__card-first">
                                <div className="card-header border-0">
                                <h2
                                    className="title-medium-2-semi-bold mb-0"
                                    data-element="feedback-title"
                                >
                                    Quanto sono chiare le informazioni su questa pagina?
                                </h2>
                                </div>
                                <div className="card-body">
                                <fieldset className="rating">
                                    <input
                                    type="radio"
                                    id="star5a"
                                    name="ratingA"
                                    defaultValue={5}
                                    />
                                    <label
                                    className="full rating-star"
                                    htmlFor="star5a"
                                    data-element="feedback-rate-5"
                                    >
                                    <svg
                                        className="icon icon-sm"
                                        role="img"
                                        aria-labelledby="5-star"
                                    >
                                        <use href="#it-star-full" />
                                    </svg>
                                    <span className="visually-hidden" id="5-star">
                                        Valuta 5 stelle su 5
                                    </span>
                                    </label>
                                    <input
                                    type="radio"
                                    id="star4a"
                                    name="ratingA"
                                    defaultValue={4}
                                    />
                                    <label
                                    className="full rating-star"
                                    htmlFor="star4a"
                                    data-element="feedback-rate-4"
                                    >
                                    <svg
                                        className="icon icon-sm"
                                        role="img"
                                        aria-labelledby="4-star"
                                    >
                                        <use href="#it-star-full" />
                                    </svg>
                                    <span className="visually-hidden" id="4-star">
                                        Valuta 4 stelle su 5
                                    </span>
                                    </label>
                                    <input
                                    type="radio"
                                    id="star3a"
                                    name="ratingA"
                                    defaultValue={3}
                                    />
                                    <label
                                    className="full rating-star"
                                    htmlFor="star3a"
                                    data-element="feedback-rate-3"
                                    >
                                    <svg
                                        className="icon icon-sm"
                                        role="img"
                                        aria-labelledby="3-star"
                                    >
                                        <use href="#it-star-full" />
                                    </svg>
                                    <span className="visually-hidden" id="3-star">
                                        Valuta 3 stelle su 5
                                    </span>
                                    </label>
                                    <input
                                    type="radio"
                                    id="star2a"
                                    name="ratingA"
                                    defaultValue={2}
                                    />
                                    <label
                                    className="full rating-star"
                                    htmlFor="star2a"
                                    data-element="feedback-rate-2"
                                    >
                                    <svg
                                        className="icon icon-sm"
                                        role="img"
                                        aria-labelledby="2-star"
                                    >
                                        <use href="#it-star-full" />
                                    </svg>
                                    <span className="visually-hidden" id="2-star">
                                        Valuta 2 stelle su 5
                                    </span>
                                    </label>
                                    <input
                                    type="radio"
                                    id="star1a"
                                    name="ratingA"
                                    defaultValue={1}
                                    />
                                    <label
                                    className="full rating-star"
                                    htmlFor="star1a"
                                    data-element="feedback-rate-1"
                                    >
                                    <svg
                                        className="icon icon-sm"
                                        role="img"
                                        aria-labelledby="1-star"
                                    >
                                        <use href="#it-star-full" />
                                    </svg>
                                    <span className="visually-hidden" id="1-star">
                                        Valuta 1 stelle su 5
                                    </span>
                                    </label>
                                </fieldset>
                                </div>
                            </div>
                            <div
                                className="cmp-rating__card-second d-none"
                                data-step={3}
                            >
                                <div className="card-header border-0">
                                <h2
                                    className="title-medium-2-bold mb-0"
                                    id="rating-feedback"
                                >
                                    Grazie, il tuo parere ci aiuterà a migliorare il
                                    servizio!
                                </h2>
                                </div>
                            </div>
                            <div className="form-rating d-none">
                                <div className="d-none rating-shadow" data-step={1}>
                                <div className="cmp-steps-rating">
                                    <fieldset
                                    className="fieldset-rating-one d-none"
                                    data-element="feedback-rating-positive"
                                    >
                                    <legend className="iscrizioni-header w-100">
                                        <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                        <span
                                            className="d-block d-lg-inline"
                                            data-element="feedback-rating-question"
                                        >
                                            Quali sono stati gli aspetti che hai
                                            preferito?{" "}
                                        </span>
                                        <span className="step">1/2</span>
                                        </h3>
                                    </legend>
                                    <div className="cmp-steps-rating__body">
                                        <div className="cmp-radio-list">
                                        <div className="card-teaser shadow-rating">
                                            <div className="card-body">
                                            <div className="form-check m-0">
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating1"
                                                    type="radio"
                                                    id="radio-1"
                                                />
                                                <label
                                                    htmlFor="radio-1"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Le indicazioni erano chiare
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating1"
                                                    type="radio"
                                                    id="radio-2"
                                                />
                                                <label
                                                    htmlFor="radio-2"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Le indicazioni erano complete
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating1"
                                                    type="radio"
                                                    id="radio-3"
                                                />
                                                <label
                                                    htmlFor="radio-3"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Capivo sempre che stavo procedendo
                                                    correttamente
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating1"
                                                    type="radio"
                                                    id="radio-4"
                                                />
                                                <label
                                                    htmlFor="radio-4"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Non ho avuto problemi tecnici
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating1"
                                                    type="radio"
                                                    id="radio-5"
                                                />
                                                <label
                                                    htmlFor="radio-5"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Altro
                                                </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </fieldset>
                                    <fieldset
                                    className="fieldset-rating-two d-none"
                                    data-element="feedback-rating-negative"
                                    >
                                    <legend className="iscrizioni-header w-100">
                                        <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                        <span
                                            className="d-block d-lg-inline"
                                            data-element="feedback-rating-question"
                                        >
                                            Dove hai incontrato le maggiori difficoltà?
                                        </span>
                                        <span className="step">1/2</span>
                                        </h3>
                                    </legend>
                                    <div className="cmp-steps-rating__body">
                                        <div className="cmp-radio-list">
                                        <div className="card-teaser shadow-rating">
                                            <div className="card-body">
                                            <div className="form-check m-0">
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating2"
                                                    type="radio"
                                                    id="radio-6"
                                                />
                                                <label
                                                    htmlFor="radio-6"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    A volte le indicazioni non erano
                                                    chiare
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating2"
                                                    type="radio"
                                                    id="radio-7"
                                                />
                                                <label
                                                    htmlFor="radio-7"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    A volte le indicazioni non erano
                                                    complete
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating2"
                                                    type="radio"
                                                    id="radio-8"
                                                />
                                                <label
                                                    htmlFor="radio-8"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    A volte non capivo se stavo
                                                    procedendo correttamente
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating2"
                                                    type="radio"
                                                    id="radio-9"
                                                />
                                                <label
                                                    htmlFor="radio-9"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Ho avuto problemi tecnici
                                                </label>
                                                </div>
                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                <input
                                                    name="rating2"
                                                    type="radio"
                                                    id="radio-10"
                                                />
                                                <label
                                                    htmlFor="radio-10"
                                                    data-element="feedback-rating-answer"
                                                >
                                                    Altro
                                                </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </fieldset>
                                </div>
                                </div>
                                <div className="d-none" data-step={2}>
                                <div className="cmp-steps-rating">
                                    <fieldset>
                                    <legend className="iscrizioni-header w-100">
                                        <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow mb-4">
                                        <span className="d-block d-lg-inline">
                                            Vuoi aggiungere altri dettagli?{" "}
                                        </span>
                                        <span className="step">2/2</span>
                                        </h3>
                                    </legend>
                                    <div className="cmp-steps-rating__body">
                                        <div className="form-group shadow-rating">
                                        <label htmlFor="formGroupExampleInputWithHelp">
                                            Dettaglio
                                        </label>
                                        <input
                                            className="form-control"
                                            id="formGroupExampleInputWithHelp"
                                            aria-describedby="formGroupExampleInputWithHelpDescription"
                                            maxLength={200}
                                            type="text"
                                            data-element="feedback-input-text"
                                        />
                                        <small
                                            id="formGroupExampleInputWithHelpDescription"
                                            className="form-text"
                                        >
                                            Inserire massimo 200 caratteri
                                        </small>
                                        </div>
                                    </div>
                                    </fieldset>
                                </div>
                                </div>
                                <div className="d-flex flex-nowrap pt-4 w-100 justify-content-center">
                                <button
                                    className="btn btn-outline-primary fw-bold me-4 btn-back"
                                    type="button"
                                >
                                    Indietro
                                </button>
                                <button
                                    className="btn btn-primary fw-bold btn-next"
                                    type="submit"
                                    form="rating"
                                >
                                    Avanti
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
        </React.Fragment>
    )
}

export default Sixth;