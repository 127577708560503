import React from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import FormSlider from "../../UI-component/ui-el/FormSlider";
import CustomerPropertyFormBox from "../../UI-component/registryForms/CustomerPropertyFormBox";
import SideList from "../../UI-component/SideList";
import SavingForm from "../../UI-component/SavingForm";

export default function PagamentiIMUDatiPersonali() {
  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Contribuente", id: "#taxpayer" },
          { element: "Coobligato", id: "#co-obliged" },
          { element: "Immobili", id: "#properties" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="it-page-sections-container">
          {/* TODO: Customer cards created dynamially using api data */}

          <CustomerFormBox id="taxpayer"
            cards={[
              { name: "giulia rossi", taxID: "123" },
              { name: "sara bianchi", taxID: "1234" },
            ]}
            type={true}
            description="Seleziona o aggiungi il contribuente che pagherà l’imposta"
            title="Contribuente"
          />

          <FormSlider  id="co-obliged" />

          <CustomerPropertyFormBox  id="properties"
            cards={[
              { name: "H501- Roma", type: "abitazione principale" },
              { name: "L736- Venezia", type: "Altro immobile" },
            ]}
          />
        </div>

        {/* TODO: ending buttons */}
        {/* <SavingForm /> */}
      </div>
    </div>
  );
}
