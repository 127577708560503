import React from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../context/StepsContext";


function SelectionFormBox(props) {
    const dispatcher = useDispatch();

    const dynamicChanges = (e) => {
      const id = e.target.parentNode
        .children[0]
        .textContent
        .trim()
        .toLowerCase()
        .replace(" ","_");

        dispatcher(stepSlice.actions.dynamicSave({id:"Preferenze",internalId: id, data: e.target.options[e.target.selectedIndex].textContent,remove: false}));
    }

    return (
        <section className="it-page-section" id="school-choice">
            <div className="cmp-card mb-40">
            <div className="card has-bkg-grey shadow-sm p-big p-4">
                <div className="card-header border-0 p-0 mb-lg-30">
                <div className="d-flex">
                    <h2 className="title-xxlarge mb-0 icon-required">
                    {props.title}
                    </h2>
                </div>
                <p className="subtitle-small mb-0">
                    {props.description}
                </p>
                </div>
                <div className="card-body p-0">
                    <div className="select-wrapper p-big p-4 select-partials">
                        <p style={{display: "none"}}>{props.title}</p>
                        <label htmlFor="select-school" className="visually-hidden">
                            {props.selectTitle}
                        </label>
                        <select id="select-school" className required onChange={dynamicChanges}>
                            <option selected="selected" disabled>
                                Seleziona un'opzione
                            </option>
                            {
                                props.choices.map(option => {
                                    if(props.defaultValue === option.value) {
                                        return (
                                            <option selected="selected" value={option.id} key={Math.random() * 31 * Math.random() * 11}>
                                                {option.value}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option value={option.id} key={Math.random() * 31 * Math.random() * 11}>
                                                {option.value}
                                            </option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
                {props.children}
            </div>
            </div>
        </section>
    )
}

export default SelectionFormBox;