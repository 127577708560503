import React, { useState } from "react";
import RegistryEditPopup from "../editForms/RegistryEditPopup";
import AddressesEditPopup from "../editForms/AddressesEditPopup";
import ContactEditPopup from "../editForms/ContactEditPopup";
import DocumentEditPopup from "../editForms/DocumentEditPopup";

function CustomerRegistry(props) {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState({
    registry: false,
    addresses: false,
    contacts: false,
    documents: false,
  });

  const dynamicRegistryFormBuilder = () => {
    return Object.keys(props.registryData).map((k) => {
      let title = "";

      switch (k) {
        case "reg":
          title = "Anagrafica";
          break;
        case "addresses":
          title = "Indirizzi";
          break;
        case "contacts":
          title = "Contatti";
          break;
        case "documents":
          title = "Documenti";
          break;
        default:
      }

      return (
        <>
          <div className="accordion-collapse">
            <div className="accordion-body p-0">
              <div className="cmp-info-summary bg-white has-border">
                <div className="card">
                  <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                    <h4 className="title-large-semi-bold mb-3">{title}</h4>
                    <p
                      className="text-decoration-none"
                      onClick={editData}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        data-type={k}
                        className="text-button-sm-semi t-primary"
                      >
                        Modifica
                      </span>
                    </p>
                  </div>
                  <div className="card-body p-0">
                    {

                      Object.keys(props.registryData[k]).map((i) => {
                        return (
                          <React.Fragment key={Math.random() * 31 * Math.random() * 11}>
                            <div className="single-line-info border-light">
                              <div className="text-paragraph-small">{i}</div>
                              <div className="border-light">
                                <p className="data-text">
                                  {props.registryData[k][i]}
                                </p>
                                {k.toLocaleLowerCase() === "documents" && (
                                  <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                    <span className="d-flex align-items-center">
                                      <svg
                                        className="icon icon-sm icon-success"
                                        aria-hidden="true"
                                      >
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                      </svg>
                                      Documento inserito con successo
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="card-footer p-0"></div>
                          </React.Fragment>
                        );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  const showRegistry = () => {
    setShow((prev) => !prev);
  };

  const editData = (e) => {
    switch (e.target.dataset.type) {
      case "reg":
        setEdit((prev) => {
          return {
            ...prev,
            registry: true,
            addresses: false,
            contacts: false,
            documents: false,
          };
        });
        break;
      case "addresses":
        setEdit((prev) => {
          return {
            ...prev,
            registry: false,
            addresses: true,
            contacts: false,
            documents: false,
          };
        });
        break;
      case "contacts":
        setEdit((prev) => {
          return {
            ...prev,
            registry: false,
            addresses: false,
            contacts: true,
            documents: false,
          };
        });
        break;
      case "documents":
        setEdit((prev) => {
          return {
            ...prev,
            registry: false,
            addresses: false,
            contacts: false,
            documents: true,
          };
        });
        break;
      default:
        setEdit((prev) => {
          return {
            ...prev,
            registry: false,
            addresses: false,
            contacts: false,
            documents: false,
          };
        });
        break;
    }
  };

  const closeEdit = () => {
    setEdit((prev) => {
      return {
        ...prev,
        registry: false,
        addresses: false,
        contacts: false,
        documents: false,
      };
    });
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" id="heading-collapse-benef-1">
        <button
          className="collapsed accordion-button"
          type="button"
          onClick={showRegistry}
        >
          <span className="d-flex align-items-center">
            Mostra tutto
            <svg className="icon icon-primary icon-sm">
              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
            </svg>
          </span>
        </button>
      </div>
      {show && dynamicRegistryFormBuilder()}

      {edit.registry && <RegistryEditPopup close={closeEdit} id={props.id} taxID={props.taxID}/>}

      {edit.addresses && <AddressesEditPopup close={closeEdit} id={props.id} taxID={props.taxID}/>}

      {edit.contacts && <ContactEditPopup close={closeEdit} id={props.id} taxID={props.taxID}/>}

      {edit.documents && <DocumentEditPopup close={closeEdit} id={props.id} taxID={props.taxID}/>}
    </div>
  );
}

export default CustomerRegistry;
