import React, {useState } from "react";
import styled from "styled-components";


const FileLoaderController = styled.label`
    background: red;
    cursor: pointer;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    background: #9f2338;
    color: whitesmoke;
    font-weight: bolder;
    

    &:hover {
        opacity: .9;
    }
`;

function FileLoader(props) {
    const [file,setFile] = useState("");

    const fileAdder = (e) => {
        setFile(e.target.files[0].name);
    }

    return (
      <>
        <h6>{props.title}</h6>
        <label style={{color: "green", fontWeight: "bold", padding: "10px"}}>{file}</label>
        <FileLoaderController>
            <svg class="icon icon-white icon-sm" aria-hidden="true"><use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-upload"></use></svg>
            Carica File
            <input type="file" style={{display: "none"}} onChange={fileAdder}/>
        </FileLoaderController>
      </>
    )
}

export default FileLoader;