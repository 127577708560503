import React from "react";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import SavingForm from "../../UI-component/SavingForm";

export default function PagamentiIMURiepilogo(props) {
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        <div className="callout callout-highlight ps-3 warning">
          <div className="callout-title mb-20 d-flex align-items-center">
            <svg className="icon icon-sm" aria-hidden="true">
              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-horn" />
            </svg>
            <span>Attenzione</span>
          </div>
          <p className="titillium text-paragraph">
            Le informazioni che hai fornito hanno valore di dichiarazione.
            <span className="d-lg-block"> Verifica che siano corrette.</span>
          </p>
        </div>
        <h2 className="title-xxlarge mb-4 mt-40">Dati Generali</h2>
        {/* TODO: Summary card created dynamially using api data */}

        <SummaryCard mod={true} cardTitle="Giulia Bianchi" type="Contribuenti">
          <FormBoxField title="Codice Fiscale" value="GLABNC72H25H501Y" />
          <FormBoxField title="Data Di Nascita" value="02/03/1975" />
          <FormBoxField title="Luogo Di Nascita" value="Roma" />
          <FormBoxField title="Sesso" value="Femmina" />
        </SummaryCard>

        <SummaryCard mod={true} cardTitle="Giovanni Rossi" type="Coobligato">
          <FormBoxField title="Codice Fiscale" value="GLABNC72H25H501Y" />
          <FormBoxField title="Data Di Nascita" value="02/03/1975" />
          <FormBoxField title="Luogo Di Nascita" value="Roma" />
          <FormBoxField title="Sesso" value="Femmina" />
        </SummaryCard>

        <SummaryCard mod={true} cardTitle="H501 - Roma" type="Immobili">
          <FormBoxField title="Codice Fiscale" value="GLABNC72H25H501Y" />
          <FormBoxField title="Data Di Nascita" value="02/03/1975" />
          <FormBoxField title="Luogo Di Nascita" value="Roma" />
          <FormBoxField title="Sesso" value="Femmina" />
        </SummaryCard>

        <SummaryCard mod={true} cardTitle="L736 - Venezia" type="Immobili">
          <FormBoxField title="Tipologia Immobile" value="Altro immobile" />
          <FormBoxField title="Rendita Catastale" value="471,00€" />
          <FormBoxField title="Aliquota IMU" value="2%" />
          <FormBoxField title="Aliquota acconto / base IMU" value="2%" />
          <FormBoxField title="Quota di possesso" value="100%" />
          <FormBoxField title="Mese Di Possesso" value="12" />
          <FormBoxField title="Immobile storico / inagibile" value="No" />
          <FormBoxField title="Ulteriori contitolari" value="0" />
          <FormBoxField title="Pertinenze" value="No" />
        </SummaryCard>

        {/* TODO: needs save and save request events */}
        {/* <SavingForm /> */}
      </div>
    </div>
  );
}
