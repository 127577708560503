import React from "react";
import IseeRegistry from "./IseeRegistry";
import { useSelector } from "react-redux";

function IseeCard(props) {
    const iseeCtx = useSelector(state => state.stepSlice.data.isee);

    
    return ( 
        <div className="cmp-info-button-card radio-card">
                <>
                    <div className="card p-3 p-lg-4">

                        <div className="card-header mb-0 p-0">
                        <div className="form-check m-0">
                            <input className="radio-input" name="beneficiaries" type="radio" id="first" />
                            <label htmlFor="first">
                                <h3 className="big-title mb-0">{"SVILUPPO"}</h3>
                            </label>
                        </div>
                        </div>
                            <div className="card-body p-0">
                            <label htmlFor="second">Scadenza Isee</label>
                                <p className="card-info">{iseeCtx[0]?.scadenzaIsee ? iseeCtx[0]?.scadenzaIsee : "Non Presente" }<br /> <span>{props.info?.date}</span></p>
                                <IseeRegistry id={props.id} card={props.info?.cards}/>
                            </div>
                    </div>
                    
                </>
        </div>
  )
}

export default IseeCard;