import React, { useState } from "react";

import CustomerCardHeader from "../registryForms/CustomerCardHeader";
import CustomerCard from "../registryForms/CustomerCard";
import {AddConsumerPopup} from "../AddingForms/AddConsumerPopup";

function CustomerFormBox(props) {
    const [show,setShow] = useState(false);

    const addCustomer = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    return ( 
       <>
        <section className="it-page-section" id={props.id}>
            <div className="cmp-card mb-40">
                { 
                    show &&
                        <AddConsumerPopup close={close} id={props.title}/>    
                }    
                    
                <div className="card has-bkg-grey shadow-sm p-big">
                    <CustomerCardHeader description={props.description} type={props.type} title={props.title}/>

                    <div className="card-body p-0">
                        {
                            props?.cards !== undefined &&
                                props.cards.map(card => {
                                    const idx = Math.random() * 31 * Math.random() * 11;
                                    if(card && card.length > 0) 
                                        return <CustomerCard info={card} key={idx} title={props.title}/>
                                })
                        }
                        {/* adder button */}
                        <button type="button" className="btn plus-text mt-20 p-0" onClick={addCustomer}>
                            <span className="rounded-icon">
                                <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                </svg>
                            </span>
                            <span className>Aggiungi contribuente</span>
                        </button>
                    </div>
                </div> 
            
            </div>
        </section>
       </>
  )
}


export default CustomerFormBox;