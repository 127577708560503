import React from "react";


function HelpBox(props) {


    //props needed links,tel-numbers,spanNames
    return (
        <div className="bg-grey-card">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-3 py-5">
                <div className="cmp-contacts">
                  <div className="card w-100">
                    <div className="card-body">
                      <h2 className="title-medium-2-semi-bold">
                        Contatta il comune
                      </h2>
                      <ul className="contact-list p-0">
                        <li>
                          <a
                            className="list-item"
                            href={props.muniQuestionLink}
                          >
                            <svg
                              className="icon icon-primary icon-sm"
                              aria-hidden="true"
                            >
                              <use href="#it-help-circle" />
                            </svg>
                            <span>Leggi le domande frequenti</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="list-item"
                            href={props.muniHelpLink}
                            data-element="contacts"
                          >
                            <svg
                              className="icon icon-primary icon-sm"
                              aria-hidden="true"
                            >
                              <use href="#it-mail" />
                            </svg>
                            <span>Richiedi assistenza</span>
                          </a>
                        </li>
                        <li>
                          <a className="list-item" href={props.greenNumber}>
                            <svg
                              className="icon icon-primary icon-sm"
                              aria-hidden="true"
                            >
                              <use href="#it-hearing" />
                            </svg>
                            <span>Numero verde +39 0182 668000</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="list-item"
                            href={props.bookLink}
                            data-element="appointment-booking"
                          >
                            <svg
                              className="icon icon-primary icon-sm"
                              aria-hidden="true"
                            >
                              <use href="#it-calendar" />
                            </svg>
                            <span>Prenota appuntamento</span>
                          </a>
                        </li>
                      </ul>
                      <h2 className="title-medium-2-semi-bold mt-4">
                        Problemi in città
                      </h2>
                      <ul className="contact-list p-0">
                        <li>
                          <a className="list-item" href={props.rDisservice}>
                            <svg
                              className="icon icon-primary icon-sm"
                              aria-hidden="true"
                            >
                              <use href="#it-map-marker-circle" />
                            </svg>
                            <span>Segnala disservizio</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}


export default HelpBox;