import React from "react";


function PreferenceField(props) {
    return (
        <div className="info-wrapper-payment d-flex flex-lg-column">
            <p className="info-wrapper__label me-2 me-md-0">{props.title}</p>
            <span className="fw-semibold">{props.value}</span>
        </div>
    )
}


export default PreferenceField;