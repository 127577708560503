import React  from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../context/StepsContext";

function ChoiceFormBox(props) {
    const dispatcher = useDispatch();

    const dynamicChanges = (e) => {
      const id = e.target.parentNode
        .children[0]
        .textContent
        .trim()
        .toLowerCase()
        .replace(" ","_");
    
        if(e.target.checked)
          dispatcher(stepSlice.actions.dynamicSave({id:"Preferenze",internalId: id, data: e.target.value,remove: false}));
        else
          dispatcher(stepSlice.actions.dynamicSave({id:"Preferenze",internalId: id, data: e.target.value,remove: true}));
    }
  
    return (
      <section className="it-page-section" id="rate-choice">
      <div className="cmp-card mb-40">
        <div className="card has-bkg-grey shadow-sm p-big">
          <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
              <h2 className="title-xxlarge mb-1 icon-required">
                {props.title}
              </h2>
            </div>
            <p className="subtitle-small mb-0 mb-4">{props.description}</p>
          </div>

          <div className="card-body p-0">
            <div className="cmp-card-radio">
              <div className="card card-teaser">
                <div className="card-body">
                  <div className="form-check m-0">
                    {
                      props.choices.map((choice,idx) => {
                        const id = parseInt((Math.random() * 11 * Math.random() * 31) + idx + 1);
                        if(Array.isArray(props.defaultValue) && props.defaultValue.length > 0) {
                            if(props.defaultValue.includes(choice?.value)) {
                              return (
                                <div className="radio-body border-bottom border-light" key={Math.random() * 31 * Math.random() * 11}>
                                  <p style={{display: "none"}}>{props.title}</p>
                                  <input name="rate" type={props.type} id={`rate-${id}`} onChange={dynamicChanges} value={choice?.value} required checked/>
                                  <label htmlFor={`rate-${id}`}>{choice?.value}</label>
                                </div>
                              )
                            }
                            else {
                              return (
                                <div className="radio-body border-bottom border-light" key={Math.random() * 31 * Math.random() * 11}>
                                  <p style={{display: "none"}}>{props.title}</p>
                                  <input name="rate" type={props.type} id={`rate-${id}`} onChange={dynamicChanges} value={choice?.value} required/>
                                  <label htmlFor={`rate-${id}`}>{choice?.value}</label>
                                </div>
                              )
                            }      
                        } else {
                          if(props.defaultValue === choice?.value) {
                            return (
                              <div className="radio-body border-bottom border-light" key={Math.random() * 31 * Math.random() * 11}>
                                <p style={{display: "none"}}>{props.title}</p>
                                <input name="rate" type={props.type} id={`rate-${id}`} onChange={dynamicChanges} value={choice?.value} required checked/>
                                <label htmlFor={`rate-${id}`}>{choice?.value}</label>
                              </div>
                            )
                          }else {
                            return (
                              <div className="radio-body border-bottom border-light" key={Math.random() * 31 * Math.random() * 11}>
                                <p style={{display: "none"}}>{props.title}</p>
                                <input name="rate" type={props.type} id={`rate-${id}`} onChange={dynamicChanges} value={choice?.value} required/>
                                <label htmlFor={`rate-${id}`}>{choice?.value}</label>
                              </div>
                            )
                          }
                        }
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    )
}


export default ChoiceFormBox;











