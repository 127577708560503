import React from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";

//OFFICE SELECTOR
function Fourth(props) {
    return (
        <React.Fragment>
            <SideList infoList={[{element: "Richiedente", id: "#applicant" }]}/>
            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                {/* TODO: Summary card created dynamially using api data */}
                <SummaryCard mod={false} type="Richiedente">
                    <PopupTextField fieldType="text" fieldTitle="Nome" description="Inserisci il Nome"/>
                    <PopupTextField fieldType="text" fieldTitle="Cognome"  description="Inserisci il Cognome"/>
                    <PopupTextField fieldType="text" fieldTitle="Email"  description="Inserisci La tua Mail"/>
                </SummaryCard>
            </section>
        </React.Fragment>
    )
}

export default Fourth;
