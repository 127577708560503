import React from "react";
import SideList from "../../UI-component/SideList";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import SavingForm from "../../UI-component/SavingForm";

//OFFICE SELECTOR
function Second(props) {
    return (
        <React.Fragment>
            <SideList infoList={[{element: "Ufficcio", id: "#office" },{element: "Appuntamenti Disponibili", id: "#appuntamenti-disponibili" }]}/>
            <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <section>
                    {/* TODO: selection card created dynamially using api data */}

                    <SelectionFormBox
                        title="Appuntamenti Disponibili"
                        description=""
                        selectTitle="Seleziona l'ufficio"
                        choices={[
                            { "id":"1" ,"title" : "Gennaio"},
                            { "id":"2" ,"title" : "Febbraio"},
                            { "id":"3" ,"title" : "Marzo"},
                            { "id":"4" ,"title" : "Aprile"},
                            { "id":"5" ,"title" : "Maggio"},
                            { "id":"6" ,"title" : "Giugno"},
                            { "id":"7" ,"title" : "Luglio"},
                            { "id":"8" ,"title" : "Agosto"},
                            { "id":"9" ,"title" : "Settembre"},
                            { "id":"10" ,"title" : "Ottobre"},
                            { "id":"11" ,"title" : "Novembre"},
                            { "id":"12" ,"title" : "Dicembre"},
                        ]}
                    >
                        <div class="cmp-card-radio-list mt-4">
                            <div class="card p-3">
                                <div class="card-body p-0">
                                    <div class="form-check m-0">
                                        <fieldset id="radio-appointment">Nessunn appuntamento disponibile.</fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SelectionFormBox>
                </section>
                {/* TODO: save and savequest event needed */}

                {/* <SavingForm/> */}
            </div>
        </React.Fragment>
    )
}

export default Second;