import React from "react";


function PropertyCardHeader() {
    //formtitle, description,hasType,options
    return (
        <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
                <h2 className="title-xxlarge mb-1">Immobili</h2>
            </div>
            <p className="subtitle-small mb-0">Seleziona o aggiungi uno o piu immobili per i quali pagherai l'imposta</p>
            <br></br>
        </div>
    )
}


export default PropertyCardHeader;