import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import HelpBox from "../../UI-component/HelpBopx";
import CompactInfo from "../../UI-component/CompactInfo";
import SideList from "../../UI-component/SideList";
import { CategoryAPI } from "../../Apis/CategoryAPI";
import { ServiceRequestAPI } from "../../Apis/ServiceRequestAPI";

import SavingForm from "../../UI-component/SavingForm";
import Privacy from "../_Partials/Privacy";

import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import SuccessfullBox from "../../UI-component/SuccessfullBox";
import StarForm from "../../UI-component/StarForm";

import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

const dataForCtegoryEservices = 
{
  "success": true,
  "data": [
      {
          "id": 1,
          "external_ref": null,
          "title": "Agricoltura e pesca",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 2,
          "external_ref": null,
          "title": "Ambiente",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 3,
          "external_ref": null,
          "title": "Anagrafe e stato civile",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 4,
          "external_ref": null,
          "title": "Appalti pubblici",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 6,
          "external_ref": null,
          "title": "Catasto e urbanistica",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 7,
          "external_ref": null,
          "title": "Cultura e tempo libero",
          "description": null,
          "services": [
              {
                  "id": "CPA",
                  "external_ref": null,
                  "title": "Prenotazione appuntamento",
                  "description": null,
                  "data": null,
                  "date_start": null,
                  "date_end": null,
                  "status": false
              }
          ]
      },
      {
          "id": 8,
          "external_ref": null,
          "title": "Educazione e formazione",
          "description": null,
          "services": []
      }
  ],
  "message": "Categories retrieved successfully."
}

export default function RichiestaAssistenza(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [category, setCategory] = useState([]);
  const [service, setService] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [selection,setSelection] = useState("");
  const [formData,setFormData] = useState({
    applicant: {
      Nome: "",
      Cognome: "",
      Email: "",
    },
    request: {
      servizio: "",
      categoriaDiServizio: "",
      dettaglio: ""
    }
  });

  const logFormRef = useRef(null);
  const btnLogRef = useRef(null);

  const submitLoginForm = async (event) => {
    event.preventDefault();

    //TODO: add form validation

    const formData = new FormData(logFormRef.current);
    const formDataJSON = Object.fromEntries(formData);

    btnLogRef.current.setAttribute("disabled", true);

    try {
      const response = await ServiceRequestAPI.sendServiceRequest(
        { service_id: "CRA", status: "Inviato", data: formDataJSON },
        false
      );

      btnLogRef.current.removeAttribute("disabled");

      console.log(response);
      // setEmailToSend(response.data.data.email);
      setActiveStep(2);
    } catch (error) {
      btnLogRef.current.value = "Login";
      btnLogRef.current.removeAttribute("disabled");
      //TODO: change it
      alert("Oops! Some error occured.");
    }
  };

  const send = async () => {
    dispatcher(stepSlice.actions.dynamicSave({id: "Richiesta",data: formData}));

    setActiveStep(prev => {
     if(prev > 0 && prev <= 2)
        return prev + 1;
    })


    try{
      const res = await ServiceRequestAPI.sendServiceRequest(
        { service_id: "CRA", status: "Bozza", data: formData },
        false
      );
        
      if(!res.success) return; 
    }catch(err) {
      console.log(err);
    }
  }

  const saveRequest = async () => {
    try {
      dispatcher(stepSlice.actions.dynamicSave({id: "Richiesta",data: formData}));

      const res = await CategoryAPI.getCategory(false);
      
      if (category.success) 
        setCategory(category.data);
      console.log(res);
    } catch (err) {
      //TODO: add Alert with message
      console.log("getCategory error", err);
    }
  }

  const onDataChange = (e) => {
    const id = e.target.id;

    setFormData(prev => {
        let modified = {...prev};

        Object.keys(modified).map(key => {
          Object.keys(modified[key]).map(iKey => {
            if(id === iKey) 
              modified[key][iKey] = e.target.value;
          })
        })
        return modified;
    })
  }

  const onSelectData = (e) => {
    setFormData(prev => {
        const newObj = {...prev};
        newObj["request"][e.target.id] = e.target.options[e.target.selectedIndex].text;
        return newObj;
    })
    setSelection(e.target.options[e.target.selectedIndex].text);
  }

  const retrieveServiceCategory = () => {
    const data = dataForCtegoryEservices.data;
    const categories = [];
    const services = new Map();

    data.forEach(itm => {
      categories.push(itm.title);
      const tmp = [];
      itm.services.forEach(el => {
        tmp.push(el.title);
      })  
      services.set(itm.title,tmp);
    })

    return {
      categories,
      services
    }
  }
  

  const showAssociatedServices = () => {
    if(service) {
      for(let [key,itm] of Array.from(service)) {
        if(selection === key) {
          return <PopupSelectField onChange={onSelectData} id="servizio" fieldTitle={"Servizio"} options={itm}
            description="Seleziona il servizio per cui vuoi richiedere assistenza"/>
        }
      }
      return <PopupSelectField onChange={onSelectData} id="servizio" fieldTitle={"Servizio"} options={["a"]}
        description="Seleziona il servizio per cui vuoi richiedere assistenza"/>
    } 

  }
  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user); 
      // setApplicant(JSON.parse(user.data)); 
    }

    const {categories,services} = retrieveServiceCategory();
    console.log(services);
    
    setCategory(categories);
    setService(services);
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath
          workPathInfo={{
            base: ["Home", "https://stellanello.nemea.cloud/"],
            medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
            end: "Assistenza",
          }}
        />
      
        <div className="container" id="main-container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              {/* header and page description */}
              <CompactInfo
                title={"Richiesta assistenza"}
                description={`I campi contraddistinti dal simbolo asterisco sono obbligatori.`}
              />
            </div>
          </div>
        </div>

        {
          activeStep === 2 ? 
            <div id="second-step">
              <SuccessfullBox emailToSend={formData?.applicant?.Email}/>
              <StarForm/>
            </div> :
            <div id="first-step">
              <div className="container container-assistenza">
                <div className="row mt-lg-50">
                  <SideList
                    infoList={[
                      { element: "Richiedente", id: "#applicant" },
                      { element: "Richiesta", id: "#request" },
                    ]}
                  />

                  <div className="col-12 col-lg-8 offset-lg-1">
                    <Form
                      ref={logFormRef}
                      onSubmit={submitLoginForm}
                      className="steppers-content"
                      aria-live="polite"
                      id="justValidateForm"
                      noValidate="novalidate"
                    >
                      <div className="it-page-sections-container">
                        {/* TODO: USE defaultValue as soon as u have the api for the getRequest so that u can get the data from the api as the defaulTvALUES */}
                        <SummaryCard mod={false} type="Richiedente">
                          <PopupTextField defaultValue={""} onChange={onDataChange} fieldType="text" fieldTitle="Nome" description="Inserisci il Nome"/>
                          <PopupTextField defaultValue={""} onChange={onDataChange} fieldType="text" fieldTitle="Cognome"  description="Inserisci il Cognome"/>
                          <PopupTextField defaultValue={""} onChange={onDataChange} fieldType="text" fieldTitle="Email"  description="Inserisci La Mail"/>
                        </SummaryCard>

                        <SummaryCard mod={false} type="Richiesta">
                          <PopupSelectField onChange={onSelectData} id="categoriaDiServizio" fieldTitle={"Categoria Di Servizio"} options={category}
                            description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"/> 
                            {
                              showAssociatedServices()
                            }
                          <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="dettaglio"  description="Inserire massimo 600 caratteri"/>
                        </SummaryCard>

                        <Privacy info={{linkPrivacy: "",title: "informativa sulla privacy"}}/>                      
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
        }
        <SavingForm step={activeStep} totalSteps={2} send={send} saveRequest={saveRequest}/>

        <HelpBox
          helpBoxInfo={{
            muniQuestionLink:
              "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "tel:+39 0182 668000",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: "",
          }}
        />
      </main>
    </AuthenticatedTenant>
  );
}