import React from "react";

function StepHeader(props) {

    const dynamicStepHeader = () => {
        return (
            <ul>
                {
                    props?.stepList.map((element, idx) => {
                        if(props.step === 0){
                            return (
                                <React.Fragment key={Math.random() * 31 * Math.random() * 11}>
                                    {
                                        idx === 0 ?
                                            <li className="active">{element}
                                                <span className="visually-hidden">Attivo</span>
                                            </li> :
                                            <li>{element}</li>       
                                    }
                                </React.Fragment>
                            )
                        } 
                        else {
                           return (
                                <React.Fragment key={Math.random() * 31 * Math.random() * 11}>
                                    {
                                        props.step > idx &&
                                            <li className="confirmed">{element}
                                                <svg className="icon steppers-success" aria-hidden="true">
                                                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check" />
                                                </svg>
                                                <span className="visually-hidden">Confermato</span>
                                            </li>
                                    }

                                    {
                                        props.step === idx &&
                                            <li className="active">{element}
                                                <span className="visually-hidden">Attivo</span>
                                            </li> 
                                    }

                                    {
                                        props.step < idx &&
                                            <li>{element}</li>
                                    }
                                </React.Fragment>
                           )
                        }
                    })
                }
            </ul>
        )
    }


    return (
        <div className="steppers">
            <div className="steppers-header">
                { 
                    dynamicStepHeader()
                }

         
            </div>
        </div>    
    )
}

export default StepHeader;