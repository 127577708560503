import React, { useState } from "react";
import FormBoxField from "../ui-el/FormBoxField";
import PropertyEditPopup from "../editForms/PropertyEditPopup";


function PropertyRegistry(props) {
    const [show,setShow] = useState(false);
    const [edit,setEdit] = useState(false);

    const showRegistry = () => {
        setShow(prev => !prev);
    }

    const editData = () => {
        setEdit(prev => !prev);
    }

    const close = () => {
        setEdit(false);
    }

    return (
        <div className="accordion-item">
            <div className="accordion-header" id="heading-collapse-benef-1">
                <button className="collapsed accordion-button" type="button" onClick={showRegistry}>
                    <span className="d-flex align-items-center">Mostra tutto
                    <svg className="icon icon-primary icon-sm">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                    </svg>
                    </span>
                </button>
                {
                    show &&
                        <>
                            <div className="accordion-collapse">
                                <div className="accordion-body p-0">
                                    <div className="cmp-info-summary bg-white has-border">
                                        <div className="card">
                                            <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                                <h4 className="title-large-semi-bold mb-3">Immobili</h4>
                                                <p onClick={editData} className="text-decoration-none" style={{cursor: "pointer"}}><span className="text-button-sm-semi t-primary">Modifica</span></p>
                                            </div>
                                            <div className="card-body p-0">
                                                <FormBoxField title='Tipologia Immobile' value='Altro immobile'/>
                                                <FormBoxField title='Rendita Catastale' value='471,00€'/>
                                                <FormBoxField title='Aliquota IMU' value='2%'/>
                                                <FormBoxField title='Aliquota acconto / base IMU' value='2%'/>
                                                <FormBoxField title='Quota di possesso' value='100%'/>
                                                <FormBoxField title='Mese Di Possesso' value='12'/>
                                                <FormBoxField title='Immobile storico / inagibile' value='No'/>
                                                <FormBoxField title='Ulteriori contitolari' value='0'/>
                                                <FormBoxField title='Pertinenze' value='No'/>                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }

                {
                    edit && 
                        <PropertyEditPopup close={close}/>
                }

            </div>
        </div>  

    )
}

export default PropertyRegistry;