

function CardContainer(props) {
    return (
        <div className="cmp-card mb-40 mb-lg-4">
            <div className="card has-bkg-grey shadow-sm mb-0">
                <div className="card-header border-0 p-0 mb-lg-30">
                    <div className="d-flex">
                        <h3 className="subtitle-large mb-0">{props.title}</h3>
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    )

}


export default CardContainer;