import React from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import PositionButton from "../../UI-component/ui-el/PositionButton";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import FileLoader from "../../UI-component/ui-el/FileLoader";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";


function DatiSegnalazione(props) {
    return (
        <div className="row">
                    
          <SideList
            infoList={[
              { element: "Luogo", id: "#report-place" },
              { element: "Disservizio", id: "#report-info" },
              { element: "Autore Della segnalazione", id: "#report-author" },
            ]}/>

          <div className="col-12 col-lg-8 offset-lg-1">
            <div className="steppers-content" aria-live="polite">
              <div className="it-page-sections-container">

                {/* TODO: Summary card created dynamially using api data */}
                <SummaryCard mod={false} type="Luogo">
                  <PopupTextField fieldType="text" fieldTitle="Cerca un luogo" description=" "/>
                  <PositionButton/>
                </SummaryCard>
                <SummaryCard mod={false} cardTitle="" type="Disservizio*">
                  <PopupSelectField id="prtoperty-location" fieldTitle="Tipo di disservizio*" options={["H501 - Roma", "L736 - Venezia", "L065 - Tavagnacco"]}
                          description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"/>
                  <PopupTextField fieldType="text" fieldTitle="Titolo" description=" "/>
                  <PopupTextField fieldType="text" fieldTitle="Dettagli*" description="inserire al massimo 200 caratteri"/>
                  <FileLoader title="Immagini"/>
                </SummaryCard>

                {/* TODO: Customer details from api */}
                <CustomerFormBox id="taxpayer"
                  cards={[
                    { name: "giulia rossi", taxID: "123" },
                  ]}
                  type={false}
                  description="Informazione su di te"
                  title="Autore della segnalazione"
                />
              </div>

            </div>
          </div>
        </div>
    )
}


export default DatiSegnalazione;