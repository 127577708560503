import React from "react"; 
import "../../UI-component/SpidButton/index.css";
import Guest from "../../Layouts/GuestLayout";
import { SpidAPI } from "../../Apis/SpidAPI";
import { useNavigate } from "react-router-dom";
import HelpBox from "../../UI-component/HelpBopx";
import { SPIDReactButton } from "../../UI-component/SpidButton";

//For execute test SpidValidator
//  src="../assets/images/AgID.svg"
/**
 * 
 */
const extraProviderList = {
  Validator: "Validator",
  ValidatorLocal: "ValidatorLocal"
};

export default function AccessoServizi(props) {
  const navigate = useNavigate(); 

  //DEVELOPMENT ONLY DONT OPTIMIZE
  const autoLogin = async () => {
    console.log("autologin")
    let redirect = localStorage.getItem("destination");
    console.log("redirect: " + redirect);
    
    try{
        const res = await SpidAPI.autoLogin( false);
        const data = res.data;
        console.log(data);
        const token = data.access_token;
  
        console.log(token);
        if (!token) {
          alert("Unable to login. Please try after some time.");
          return;
        }
  
        localStorage.removeItem("access_token");
        localStorage.setItem("access_token", token);
        localStorage.setItem("user", JSON.stringify(data.user)); 
        navigate(redirect)
    }catch(err)
    {
      console.log("error autologin",err);
    }
  };

  //casuing equal key component error (just for testing purposes)
  const mappingProvider = () => {
    let ret = Object.entries(extraProviderList)
    .map(function (_ref3) {
      const entityName = _ref3[0];
      const entityID = _ref3[1];
      const logo = "../assets/images/logo-agid-test.png";

      return {
        protocols: ["SAML"],
        entityName: entityName,
        entityID: entityID,
        logo: logo,
        active: true,
      };
    });

    return ret;
  }

  return (
    <Guest auth={props.auth} errors={props.errors} key={Math.random() * 31 * Math.random() * 11}>
      <div className="container" id="main-container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="cmp-breadcrumbs" role="navigation">
              <nav className="breadcrumb-container" aria-label="breadcrumb">
                <ol className="breadcrumb p-0" data-element="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                    <span className="separator">/</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Accedi
                  </li>
                </ol>
              </nav>
            </div>{" "}
            <div className="cmp-heading pb-3 pb-lg-4">
              <h1 className="title-xxxlarge">Accedi</h1>

              <p className="subtitle-small">
                Per accedere al sito e ai suoi servizi, utilizza una delle
                seguenti modalità.
              </p>
            </div>
          </div>
        </div>
        <hr className="d-none d-lg-block mt-0 mb-4" />
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div className="cmp-text-button mt-3">
              <h2 className="title-xxlarge mb-0">SPID</h2>
              <div className="text-wrapper">
                <p className="subtitle-small mb-3">
                  Accedi con SPID, il sistema Pubblico di Identità Digitale.
                </p>
              </div>
              <div className="button-wrapper mb-2">
                <SPIDReactButton type="modal" url="/SPIDlogin/{{idp}}" extraProviders={mappingProvider()}     />
              </div>
              <a className="simple-link" href="https://www.spid.gov.it/cos-e-spid/come-attivare-spid/">
                Come attivare SPID{" "}
                <span className="visually-hidden">Come attivare SPID</span>
              </a>
            </div>
          </div>

        </div>
        <div className="col-12"> 
          <div className="col-2">
            <button
              id="step1Next"
              type="submit"
              className="btn btn-warning btn-sm steppers-btn-confirm btn-next-step"
              onClick={autoLogin}
            >
              <span className="text-button-sm">Auto Login</span>
              <svg className="icon icon-white icon-sm" aria-hidden="true">
                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-refresh"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <HelpBox helpBoxInfo={{
            muniQuestionLink: "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "tel:+39 0182 668000",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: ""
          }}/>
    </Guest>
  );
}
