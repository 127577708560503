

function SuccessfullBox(props) {
    return (
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
                <div className="cmp-hero">
                    <section className="it-hero-wrapper bg-white align-items-start">
                        <div className="it-hero-text-wrapper pt-0 ps-0 pb-40 pb-lg-60">
                        <div className="categoryicon-top d-flex">
                            <svg
                            className="icon icon-success mr-10 icon-sm mb-1"
                            aria-hidden="true"
                            >
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use>
                            </svg>
                            <h1
                            className="text-black hero-title"
                            data-element="page-name"
                            >
                            Richiesta inviata
                            </h1>
                        </div>
                        <p className="titillium hero-text">
                            La richiesta di assistenza è stata inviata con successo,
                            sarai ricontattato presto.
                            <br />
                            <br />
                            Abbiamo inviato il riepilogo all’email:
                            <br />
                            <strong id="email-recap">{props.emailToSend}</strong>
                        </p>
                        </div>
                    </section>
                </div>
            </div>
            </div>
        </div>
    )
}


export default SuccessfullBox;