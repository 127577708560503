import React from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";


function PropertyEditPopup(props) {
    return (
        <PopupBox close={props.close} title="Immobile"> 
            <PopupTextField fieldType="text" fieldTitle="Tipologia Immobile"/>
            <PopupTextField fieldType="text" fieldTitle="Rendita Catastale"/>
            <PopupTextField fieldType="text" fieldTitle="Aliquota IMU"/>
            <PopupTextField fieldType="text" fieldTitle="Aliquota acconto/ IMU"/>
            <PopupTextField fieldType="text" fieldTitle="Quota Possesso"/>
            <PopupTextField fieldType="number" fieldTitle="Mese Di Possesso"/>
            <PopupTextField fieldType="text" fieldTitle="Immobile Storico / inagibile"/>
            <PopupTextField fieldType="number" fieldTitle="Ulteriori Contitolari"/>
            <PopupTextField fieldType="text" fieldTitle="Pertinenze"/>
        </PopupBox>
    )
}

export default PropertyEditPopup;