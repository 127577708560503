import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import PopupSelectField from "../ui-el/PopupSelectField";
import PopupBox from "../PopupBox";


function AddPropertyPopup(props) {
    
    return (
        <PopupBox close={props.close} title="Immobile"> 
            <PopupSelectField id="prtoperty-location" fieldTitle={"Ubicazione Immobile"} options={["H501 - Roma", "L736 - Venezia", "L065 - Tavagnacco"]}/>
            <PopupSelectField id="ptoperty-type" fieldTitle={"Tipologia dell’immobile"} options={["Abitazione principale", "Altro immobile"]}/>
            
            <PopupTextField fieldType="number" fieldTitle="Rendita catastale"/>
            <PopupTextField fieldType="number" fieldTitle="Aliquota IMU"/>
            <PopupTextField fieldType="text" fieldTitle="Aliquota acconto / base IMU*"/>
            <PopupTextField fieldType="number" fieldTitle="Quota di possesso*"/>
            <PopupTextField fieldType="number" fieldTitle="Mesi di possesso"/>
            <PopupTextField fieldType="text" fieldTitle="Immobile storico / inagibile"/>
            <PopupTextField fieldType="text" fieldTitle="Ulteriori contitolari"/>
            <PopupTextField fieldType="text" fieldTitle="Pertinenze"/>              
        </PopupBox>
    )
}

export default AddPropertyPopup;