

function PreferenceToggle(props) {
    return (
        <div className="cmp-toggle pb-2 border-bottom border-light">
            <div className="toggles">
            <label className="label mb-0 active" htmlFor="toggle-1">
                {props.title}
                <input type="checkbox" id="toggle-1" className data-focus-mouse="false" />
                <span className="lever me-0" />
            </label>
            </div>
        </div>
    )
}


export default PreferenceToggle;