import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";

import stepSlice from "../../context/StepsContext";
import { useDispatch,useSelector } from "react-redux";


function RegistryEditPopup(props) {
    const dispatcher = useDispatch();
    
    const type = props.id === "Beneficiario" ? "beneficiary" : props.id === "Richiedente" ? "applicant" : "";
    const anagraficData = useSelector(state => state.stepSlice.data[type]);
   
    const [data,setData] = useState({
        reg:{
            "Nome": "",
            "Cognome": "",
            "CodiceFiscale": "",
            "DataDiNascita": "",
            "LuogoDiNascita": "",
            "Sesso": "",
            "Nazionalita": "",
            "Professione": "",
            "Scolarita": "",
        }
    });

    useEffect(() => {
        for(let i of anagraficData) {
            if(i.reg.CodiceFiscale === props.taxID) {
                setData(i.reg);
                break;
            }
        }
    },[])

    const edit = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: props.id,type:"reg",data: data}));
        props.close();
    }

    const onDataChange = (e) => {
        const id = e.target.id;
        setData(prev => {
            let modified = {...prev};
            
            Object.keys(modified).map(key => {
                if(id === key) 
                    modified[id] = e.target.value;
            })

            return modified;
        })
    }

  

    return (
        <PopupBox close={props.close} save={edit} title="Persona"> 
            <PopupTextField defaultValue={data.Nome} fieldType="text" fieldTitle="Nome" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.Cognome} fieldType="text" fieldTitle="Cognome" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.CodiceFiscale} fieldType="text" fieldTitle="CodiceFiscale" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.DataDiNascita} fieldType="date" fieldTitle="Data Di Nascita" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.LuogoDiNascita} fieldType="text" fieldTitle="Luogo Di Nascita" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.Sesso} fieldType="text" fieldTitle="Sesso" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.Nazionalita} fieldType="text" fieldTitle="Nazionalita" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.Professione} fieldType="text" fieldTitle="Professione" onChange={onDataChange}/>
            <PopupTextField defaultValue={data.Scolarita} fieldType="text" fieldTitle="Scolarita" onChange={onDataChange}/>
        </PopupBox>
    )
}

export default RegistryEditPopup;