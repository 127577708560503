import React, { useState } from "react";
import ReactDOM  from "react-dom";

import PopupTextField from "../ui-el/PopupTextField";
import PopupBox from "../PopupBox";
import "../../global.css";

import stepSlice from "../../context/StepsContext";
import { useDispatch } from "react-redux";

// Popup that gets created to add to that specific form box

function AddConsumerPopup(props) {
    const [customerData,setCustomerData] = useState({
        reg:{
            "Nome": "",
            "Cognome": "",
            "CodiceFiscale": "",
            "Data Di Nascita": "",
            "Luogo Di Nascita": "",
            "Sesso": "",
            "Nazionalita": "",
            "Professione": "",
            "Scolarita": "",
        },
        addresses: {
            "Residenza": "",
            "Domicilio": "",
        },
        contacts: {
            "Telefono": "",
            "Email": "",
            "Recapito Postale": "",
        },
        documents: {
            "Carta Di Identita": "",
            "Modulo Condizione Lavorativa": ""
        }
    });

    const dispatcher = useDispatch();

    const onDataChange = (e) => {
        const id = e.target.id;
        setCustomerData(prev => {
            let modified = {...prev};

            Object.keys(modified).map(key => {
                Object.keys(modified[key]).map(iKey => {
                    if(id === iKey) 
                        modified[key][id] = e.target.value;
                })
            })

            return modified;
        })
    }

    const save = () => {
        dispatcher(stepSlice.actions.dynamicSave({id: props.id,data: customerData}));
    }

    //TODO: edit modal, summary boxes
    //use the components when usable
    
    return (
        <PopupBox close={props.close} save={save} title="Persona"> 
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Nome"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Cognome"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="CodiceFiscale"/>
            <PopupTextField onChange={onDataChange} fieldType="date" fieldTitle="Data Di Nascita"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Luogo Di Nascita"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Sesso"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Nazionalita"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Professione"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Scolarita"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Residenza"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Domicilio"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Telefono"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Email"/>
            <PopupTextField onChange={onDataChange} fieldType="text" fieldTitle="Recapito Postale"/>
            <PopupTextField onChange={onDataChange} fieldType="file" fieldTitle="Carta Di Identita"/>
            <PopupTextField onChange={onDataChange} fieldType="file" fieldTitle="Modulo Condizione Lavorativa"/>
        </PopupBox>
    )
}


const AddConsumerPopupModal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<AddConsumerPopup close={props.close} id={props.id}/>,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export { AddConsumerPopupModal as AddConsumerPopup};