import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import ServiziPagamentoDatiPersonali from "../_Partials/ServiziPagamentoDatiPersonali";
import ServiziPagamentoDatiSpecifici from "../_Partials/ServiziPagamentoDatiSpecifici";
import ServiziPagamentoRiepilogo from "../_Partials/ServiziPagamentoRiepilogo";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import {ServiceDetailsAPI} from "../../Apis/ServiceDetailsAPI";

import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

const test = [
  {
      "module": "Privacy",
      "title": "INFORMATIVA SULLA PRIVACY",
      "description": "Il Comune di Firenze gestisce i dati personali forniti e liberamente comunicati sulla base dell’articolo 13 del Regolamento (UE) 2016/679 General data protection regulation (Gdpr) e degli articoli 13 e successive modifiche e integrazione del decreto legislativo (di seguito d.lgs) 267/2000 (Testo unico enti locali).",
      "linkPrivacy": "https://google.it"
  },
  {
      "module": "General",
      "title": "Dati Generali",
      "components": [
          {
              "type": "Person",
              "title": "Richiedente",
              "isMulti": false
          },
          {
              "type": "Person",
              "title": "Beneficiario",
              "isMulti": true
          },
          {
              "type": "Isee",
              "title": "ISEE"
          }
      ]
  },
  {
      "module": "Preference",
      "title": "Preferenze di servizio",
      "components": [
          {
              "type": "Select",
              "title": "SCUOLA",
              "options": [
                  {
                      "id": 1,
                      "value": "Scuola primaria di Via Roma"
                  }
              ]
          },
          {
              "type": "Choice",
              "title": "TARIFFA RIDUZIONE",
              "options": [
                  {
                      "id": 1,
                      "value": "Fascia A - ISEE fino a 5.000,00 euro"
                  },
                  {
                      "id": 2,
                      "value": "Fascia B - ISEE da 5.001,00 a 10.000,00 euro"
                  },
                  {
                      "id": 3,
                      "value": "Fascia C - ISEE oltre 10.001,00 euro"
                  }
              ]
          },
          {
              "type": "Checkbox",
              "title": "DIETA DIVERSIFICATA",
              "options": [
                  {
                      "id": 1,
                      "value": "nessuna dieta"
                  },
                  {
                      "id": 2,
                      "value": "dieta per patologia"
                  },
                  {
                      "id": 3,
                      "value": "dieta etico religiosa"
                  }
              ]
          }
      ]
  },
  {
      "module": "Summary",
      "title": "RIEPILOGO"
  }
]

export default function MensaScolastica(props) {
  const dispatcher = useDispatch();
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [data,setData] = useState({});

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      clearStore();
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      clearStore();
    }
    (async () => {
      await getData();
    })()
  }, []);

  const clearStore = () => {
    console.log("clearing store");
    dispatcher(stepSlice.actions.clear())
  } 

  const getData = async () => {
    try{
      const res = await ServiceDetailsAPI.getServiceDetails(props.code);

      if(!res.success) return;
      
      const data = res.data?.data;

      setData(data);
    }catch(err) {
      console.log(err);
    }
  }

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>

      <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
          end: "Servizi scolasici"
        }}/> 

      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <CompactInfo title={"Richiesta servizio mensa scolastica"} description={`
                Inserisci le informazioni necessarie per richiedere il servizio
                di mensa scolastica. <br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`}/>
          </div>
          <DynamicStepContent stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI","DATI SPECIFICI","RIEPILOGO"]} steps={[
            {component:Privacy,info: data[0] ? data[0] : "" },
            {component:ServiziPagamentoDatiPersonali,info: data[1] ? data[1] : {}},
            {component:ServiziPagamentoDatiSpecifici,info: data[2] ? data[2] : {}},
            {component:ServiziPagamentoRiepilogo,info: data[3] ? data[3] : {}},            
          ]}/>

        </div>
      </div>
    </AuthenticatedTenant>
  );
}
