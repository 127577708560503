import React from "react";
import ChoiceFormBox from "../../UI-component/ChoiceFormBox";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import SideList from "../../UI-component/SideList";
import { useSelector } from "react-redux";

export default function ServiziPagamentoDatiSpecifici(props) {
  const preferencesCtx = useSelector(state => state.stepSlice?.data?.preferences);
  const indexer = [];

  console.log(preferencesCtx);
  // filter the form rendering with the given options
  // build the forms dynamically based on the given data
  const dynamicRenderer = ({type,title,options}) => {
    switch(type) {
      case "Select":
        return  <SelectionFormBox key={keyBuilder()}
            title={title}
            description="Scegli l’istituto a cui vuoi iscrivere il minore"
            choices={options}
            defaultValue={preferencesCtx.scuola}
            />
      case "Choice":
        return <ChoiceFormBox key={keyBuilder()} type="radio"
          title={title}
          description="Indica la tariffa di esonero a cui ha diritto il beneficiario"
          choices={options}
          defaultValue={preferencesCtx.tariffa_riduzione}
          indexer={indexer}
          />
      case "Checkbox":
        return <ChoiceFormBox key={keyBuilder()} type="checkbox"
          title={title}
          description="Indica la tariffa di esonero a cui ha diritto il beneficiario"
          choices={options}
          defaultValue={preferencesCtx.dieta_diversificata}
          />
      default: return;    
    }
  }

  // creates a unique key for the components
  const keyBuilder = () => {
    return "" + (Math.random() * 31 * Math.random() * 11);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Scegli la Scuola", id: "#school-choice" },
          { element: "Tariffa esonero", id: "#rate-choice" },
          { element: "Tariffa riduzione", id: "#outings-choice" },
          { element: "Frequenza", id: "#attendance" },
          { element: "Dieta diversificata", id: "#different-diet" },
        ]}
      />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <p className="text-paragraph-small mb-40">
              I campi contraddistinti dal simbolo asterisco sono obbligatori
            </p>
            {
              props.info.components.map(itm => {
                return dynamicRenderer(itm);
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}
