import {createSlice} from "@reduxjs/toolkit";

const initState = {
    privacy: false,
    data: {
        beneficiary: [],
        applicant: [],
        isee: [
            {
                valoreIsee: '',
                scadenzaIsee: '',
                annoProtocollo: '',
                numeroProtocollo: '',
                codiceFilialeProtocollo: '',
                certificatoIsee: '',
                selected: true
            }
        ],
        preferences: {
            "scuola":"",
            "tariffa_esonero":"",
            "tariffa_riduzione": "",
            "frequenza": "",
            "dieta_diversificata":[],
        },
        application: {
            applicant: {

            },
            request: {

            }
        }
    }
}

const stepSlice = createSlice({
    name: "stepSlice",
    initialState: initState,
    reducers: {
        togglePvt,
        dynamicSave,
        dynamicEdit,
        changeSelected,
        clear
    }
})

function togglePvt(state,action) {
    state.privacy = action.payload;
}

function dynamicSave(state,action) {
    switch(action.payload.id) {
        case "Beneficiario": 
            state.data.beneficiary.push(action.payload.data);
            break;
        case "Richiedente":
            state.data.applicant.push(action.payload.data);
            break;
        case "ISEE": 
            state.data.isee.push(action.payload.data)
            break;
        case "Richiesta": 
            state.data.application = action.payload.data;
            break;
        case "Preferenze":
            const internalID = action.payload.internalId;
            const data = action.payload.data;

            if(internalID === "dieta_diversificata") {
                if(action.payload.remove) {
                    const index = state.data.preferences[internalID].findIndex(el => data === el);                    
                    state.data.preferences[internalID].splice(index,1);
                }else {
                    state.data.preferences[internalID].push(data);
                }
            }else {
                state.data.preferences[internalID] = data;
            }
            break;
        default: 
            break;
    }
}

function dynamicEdit(state,action) {
    switch(action.payload.id) {
        case "Beneficiario": 
            for(let i = 0; i < state.data.beneficiary.length; i++) {
                if(state.data.beneficiary[i].reg.CodiceFiscale === action.payload.data.CodiceFiscale) {
                    state.data.beneficiary[i].reg = action.payload.data;
                    break;
                } 
            }
            break;
        case "Richiedente":
            for(let i = 0; i < state.data.applicant.length; i++) {
                if(state.data.applicant[i].reg.CodiceFiscale === action.payload.data.CodiceFiscale) {
                    state.data.applicant[i].reg = action.payload.data;
                    break;
                } 
            }
            break;
        case "ISEE": 
            state.data.isee[0] = action.payload.data;
            break;
        case "Preferenze":
            const data = action.payload.data;
            state.data.preferences = data;
            break;
        default: 
            break;
    }
}

function changeSelected(state,action) {
    switch(action.payload.id) {
        case "Beneficiario": 
            filtering("beneficiary",state,action);
            break;
        case "Richiedente":   
            filtering("applicant",state,action);
            break;
        default: 
            break;
    } 
}

function clear(state) {
    state.privacy = false;

    state.data = {
        beneficiary: [],
        applicant: [],
        isee: [      {
            valoreIsee: '',
            scadenzaIsee: '',
            annoProtocollo: '',
            numeroProtocollo: '',
            codiceFilialeProtocollo: '',
            certificatoIsee: '',
            selected: true
        }],
        preferences: {
            "scuola":"",
            "tariffa_esonero":"",
            "tariffa_riduzione": "",
            "frequenza": "",
            "dieta_diversificata":[],
        },
        applicantion: {  
            applicant: {

            },
            request: {

            }
        }
    }
}

function filtering(id,state,action) {
    for(let i of state.data[id]) {
        if(i.reg.CodiceFiscale === action.payload?.taxID) 
            i.selected = true;
         else 
            i.selected = false;  
    } 
}

export default stepSlice;