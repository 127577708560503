

function PopupBox(props) {
    return (
        <div className="modal it-dialog-scrollable fade show" id="modal-tributes"
            aria-labelledby="modal-tributes-modal-title" style={{display: "block"}} data-focus-mouse="false" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content"  >
                <div className="cmp-modal__header modal-header pb-0">
                    <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale" onClick={props.close}>
                    </button>
                    <h2 className="cmp-modal__header-title title-large-semi-bold" id="modal-tributes-modal-title">{props.title}</h2>
                    <p className="cmp-modal__header-info">I campi contraddistinti dal simbolo asterisco sono obbligatori</p>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                <div className="modal-footer shadow flex-nowrap pt-4 w-100">
                        <button className="btn btn-outline-primary w-100 fw-bold me-4" type="button" data-bs-dismiss="modal" onClick={props.close}>Annulla</button>
                        <button className="btn btn-primary w-100 fw-bold" type="submit" form="" id="saveCustomerForm" onClick={props.save}>Salva</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupBox;