import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import { ServiceRequestAPI } from "../../Apis/ServiceRequestAPI";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import SideList from "../../UI-component/SideList";
import TabHeader from "../../UI-component/TabHeader";
import ReminderBox from "../../UI-component/ReminderBox";
import ReminderTable from "../../UI-component/ReminderTable";
import CompletitionBox from "../../UI-component/CompletitionBox";

export default function AreaPersonale(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [current, setCurrent] = useState("");
  const [fiscal_code, setFiscalCcode] = useState("");
  const [tab, setTab] = useState("");

  console.log("area personale out");

  const tabSetter = (data) => {
    setTab(data);
  }

  const tabShower = () => {
    switch(tab) {
      case "Scrivania":
        return (
          <>
            <SideList
              infoList={[
                  { element: "Ultimi Messaggi", id: "#latest-messages"},
                  { element: "Ultime Attivita", id: "#latest-activities" },
              ]}/>
            <div className="col-12 col-lg-8 offset-lg-1">
              {/* TODO: reminders got to be dynamically created from api data */}
              <ReminderTable reminders={[]} title={"Ultimi messaggi"} isDoc={false}/>
              <ReminderTable reminders={[]} title={"Ultime attivita"} isDoc={true}/>
            </div>
          </>
        )
      case "Messaggi": 
        return (
          <>
            <div className="tab-pane" id="data-ex-tab3" role="tabpanel">
                <div className="row">
                  <div className="d-none d-sm-none d-lg-block col-lg-3">
                  <div className="cmp-navscroll sticky-top" aria-labelledby="accordion-title-one">
                      <SideList
                        infoList={[
                            { element: "Pratiche", id: "#practices"},
                            { element: "Pagamenti", id: "#payments" },
                        ]}/>
                      </div>
                  </div>
                  <div className="col-12 col-lg-8 offset-lg-1 px-0 px-sm-3">
                    <section
                      className="it-page-section mb-40 mb-lg-60"
                      id="practices"
                    >
                      <div className="cmp-filter">
                        <div className="filter-section">
                          <h2 className="cmp-filter__title title-xxlarge">
                            Pratiche
                          </h2>
                          <div className="filter-wrapper d-flex align-items-center">
                            <button type="button" className="btn p-0 pe-2">
                              <span className="rounded-icon">
                                <svg
                                  className="icon icon-primary icon-xs me-1"
                                  aria-hidden="true"
                                >
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-funnel" />
                                </svg>
                              </span>
                              <span className="">Filtra</span>
                            </button>
                            <div className="dropdown">
                              <button
                                className="btn btn-dropdown dropdown-toggle"
                                type="button"
                                id="dropdownMenu-pratiche"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label=""
                              >
                                <svg className="icon-expand icon icon-sm icon-primary">
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                                </svg>
                                <span className="dropdown__title">Ordina</span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu-pratiche"
                              >
                                <div className="link-list-wrapper">
                                  <ul className="link-list">
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 1</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 2</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 3</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cmp-input-search">
                          <div className="form-group autocomplete-wrapper">
                            <div className="input-group">
                              <label
                                htmlFor="autocomplete-pratiche"
                                className="visually-hidden"
                              >
                                Cerca nel sito
                              </label>
                              <input
                                type="search"
                                className="autocomplete form-control"
                                placeholder="Cerca"
                                id="autocomplete-pratiche"
                                name="pratiche"
                                data-bs-autocomplete="[]"
                              />
                              <span
                                className="autocomplete-icon"
                                aria-hidden="true"
                              >
                                <svg className="icon icon-sm">
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-search" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cmp-accordion">
                        <div className="accordion" id="">

                          <CompletitionBox doc={
                            {
                              name:"Iscrizione alla scuola dell'infanzia",
                              state: "Conclusa",
                              date:"01/10/2052",
                              paymentCode:"PA3028/17",
                              paymentMethod:"PagoPA",
                              amount:"23,00",
                              link:"",
                              nameLink:"Scheda servizio",
                              serviceLink:"",
                              serviceNameLink:"Iscrizione scuola infanzia",
                              receipt:"Ricevuta richiesta"
                            }
                          }/>
                          {/* <div className="accordion-item">
                            <div className="accordion-header" id="heading1">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse1"
                                aria-expanded="true"
                                aria-controls="collapse1"
                              >
                                <div className="button-wrapper">
                                  Richiesta servizio mensa
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-incomplete.svg"
                                      alt="folder Da completare"
                                      role="img"
                                    />
                                    <span className="u-main-alert">
                                      Da completare
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                20/03/2022
                              </p>
                            </div>
                            <div
                              id="collapse1"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample1"
                              role="region"
                              aria-labelledby="heading1"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pratica:{" "}
                                  <span className="label">AN4059281</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Servizio non digitale
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0 p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary justify-content-center my-3"
                                >
                                  <span className="">Paga ora</span>
                                </button>
                              </div>
                            </div>
                          </div> */}
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading2">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse2"
                                aria-expanded="true"
                                aria-controls="collapse2"
                              >
                                <div className="button-wrapper">
                                  Iscrizione alla scuola dell’infanzia
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-concluded.svg"
                                      alt="folder Conclusa"
                                      role="img"
                                    />
                                    <span className="">Conclusa</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                01/10/2022
                              </p>
                            </div>
                            <div
                              id="collapse2"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample2"
                              role="region"
                              aria-labelledby="heading2"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione scuola dell’infanzia
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0 p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading3">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse3"
                                aria-expanded="true"
                                aria-controls="collapse3"
                              >
                                <div className="button-wrapper">
                                  Iscrizione corso di formazione
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-concluded.svg"
                                      alt="folder Conclusa"
                                      role="img"
                                    />
                                    <span className="">Conclusa</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                24/06/2021
                              </p>
                            </div>
                            <div
                              id="collapse3"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample3"
                              role="region"
                              aria-labelledby="heading3"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione corso formazione
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0 p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading4">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse4"
                                aria-expanded="true"
                                aria-controls="collapse4"
                              >
                                <div className="button-wrapper">
                                  Richiesta permesso ZTL
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-concluded.svg"
                                      alt="folder Conclusa"
                                      role="img"
                                    />
                                    <span className="">Conclusa</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                10/05/2021
                              </p>
                            </div>
                            <div
                              id="collapse4"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample4"
                              role="region"
                              aria-labelledby="heading4"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">Permesso ZTL</span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0 p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading5">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse5"
                                aria-expanded="true"
                                aria-controls="collapse5"
                              >
                                <div className="button-wrapper">
                                  Richiesta parcheggio residenti
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-concluded.svg"
                                      alt="folder Conclusa"
                                      role="img"
                                    />
                                    <span className="">Conclusa</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                06/03/2021
                              </p>
                            </div>
                            <div
                              id="collapse5"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample5"
                              role="region"
                              aria-labelledby="heading5"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Richiesta parcheggio residenti
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn accordion-view-more mb-2 pt-3 t-primary title-xsmall-semi-bold ps-lg-3"
                      >
                        <span className="">Vedi altre pratiche</span>
                      </button>
                    </section>
                    
                    <section
                      className="it-page-section mb-50 mb-lg-90"
                      id="payments"
                    >
                      <div className="cmp-filter">
                        <div className="filter-section">
                          <h2
                            className="cmp-filter__title title-xxlarge"
                            id="payments"
                          >
                            Pagamenti
                          </h2>
                          <div className="filter-wrapper d-flex align-items-center">
                            <button type="button" className="btn p-0 pe-2">
                              <span className="rounded-icon">
                                <svg
                                  className="icon icon-primary icon-xs me-1"
                                  aria-hidden="true"
                                >
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-funnel" />
                                </svg>
                              </span>
                              <span className="">Filtra</span>
                            </button>
                            <div className="dropdown">
                              <button
                                className="btn btn-dropdown dropdown-toggle"
                                type="button"
                                id="dropdownMenu-pagamenti"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label=""
                              >
                                <svg className="icon-expand icon icon-sm icon-primary">
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                                </svg>
                                <span className="dropdown__title">Ordina</span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu-pagamenti"
                              >
                                <div className="link-list-wrapper">
                                  <ul className="link-list">
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 1</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 2</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item list-item"
                                        href="#"
                                      >
                                        <span>Azione 3</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cmp-input-search">
                          <div className="form-group autocomplete-wrapper">
                            <div className="input-group">
                              <label
                                htmlFor="autocomplete-pagamenti"
                                className="visually-hidden"
                              >
                                Cerca nel sito
                              </label>
                              <input
                                type="search"
                                className="autocomplete form-control"
                                placeholder="Cerca"
                                id="autocomplete-pagamenti"
                                name="pagamenti"
                                data-bs-autocomplete="[]"
                              />
                              <span
                                className="autocomplete-icon"
                                aria-hidden="true"
                              >
                                <svg className="icon icon-sm">
                                  <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-search" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cmp-accordion">
                        <div className="accordion" id="">
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading6">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse6"
                                aria-expanded="true"
                                aria-controls="collapse6"
                              >
                                <div className="button-wrapper">
                                  Pagamento servizio mensa
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-to-pay.svg"
                                      alt="folder Da pagare"
                                      role="img"
                                    />
                                    <span className="u-main-error">
                                      Da pagare
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                07/01/2022
                              </p>
                            </div>
                            <div
                              id="collapse6"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample6"
                              role="region"
                              aria-labelledby="heading6"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pratica:{" "}
                                  <span className="label">AN4059281</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Servizio non digitale
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary justify-content-center my-3"
                                >
                                  <span className="">Paga ora</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading7">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse7"
                                aria-expanded="true"
                                aria-controls="collapse7"
                              >
                                <div className="button-wrapper">
                                  Pagamento contravvenzione
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-pay.svg"
                                      alt="folder Pagato"
                                      role="img"
                                    />
                                    <span className="">Pagato</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                01/10/2021
                              </p>
                            </div>
                            <div
                              id="collapse7"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample7"
                              role="region"
                              aria-labelledby="heading7"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione servizio mensa
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading8">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse8"
                                aria-expanded="true"
                                aria-controls="collapse8"
                              >
                                <div className="button-wrapper">
                                  Pagamento contravvenzione
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-pay.svg"
                                      alt="folder Pagato"
                                      role="img"
                                    />
                                    <span className="">Pagato</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                24/06/2021
                              </p>
                            </div>
                            <div
                              id="collapse8"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample8"
                              role="region"
                              aria-labelledby="heading8"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione servizio mensa
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading9">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse9"
                                aria-expanded="true"
                                aria-controls="collapse9"
                              >
                                <div className="button-wrapper">
                                  Pagamento contravvenzione
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-pay.svg"
                                      alt="folder Pagato"
                                      role="img"
                                    />
                                    <span className="">Pagato</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                10/05/2021
                              </p>
                            </div>
                            <div
                              id="collapse9"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample9"
                              role="region"
                              aria-labelledby="heading9"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione servizio mensa
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <div className="accordion-header" id="heading10">
                              <button
                                className="accordion-button collapsed title-snall-semi-bold"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse10"
                                aria-expanded="true"
                                aria-controls="collapse10"
                              >
                                <div className="button-wrapper">
                                  Pagamento contravvenzione
                                  <div className="icon-wrapper">
                                    <img
                                      className="icon-folder"
                                      src="../assets/images/folder-pay.svg"
                                      alt="folder Pagato"
                                      role="img"
                                    />
                                    <span className="">Pagato</span>
                                  </div>
                                </div>
                              </button>
                              <p className="accordion-date title-xsmall-regular mb-0">
                                06/03/2021
                              </p>
                            </div>
                            <div
                              id="collapse10"
                              className="accordion-collapse collapse p-0"
                              data-bs-parent="#accordionExample10"
                              role="region"
                              aria-labelledby="heading10"
                            >
                              <div className="accordion-body">
                                <p className="mb-2 fw-normal">
                                  Pagamento:{" "}
                                  <span className="label">PA3028/17</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Metodo: <span className="label">PagoPA</span>
                                </p>
                                <p className="mb-2 fw-normal">
                                  Importo: <span className="label">23,00 €</span>
                                </p>
                                <a href="#" className="mb-2">
                                  <span className="t-primary">
                                    Scheda servizio
                                  </span>
                                </a>
                                <a className="chip chip-simple" href="#">
                                  <span className="chip-label">
                                    Iscrizione servizio mensa
                                  </span>
                                </a>
                                <div className="cmp-icon-list">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <button
                        type="button"
                        className="btn accordion-view-more mb-2 t-primary title-xsmall-semi-bold ps-lg-3"
                      >
                        <span className="">Vedi altri pagamenti</span>
                      </button>
                    </section>
                  </div>
                </div>
            </div>
          </>
        )
      case "Attivita": 
        return (
          <></>
        )
      case "Servizi": 
        return (
          <div className="tab-pane" id="data-ex-tab4" role="tabpanel">
              <div className="row">
                <div className="col-12 col-lg-3 d-lg-block mb-4 d-none">
                  <div
                    className="cmp-navscroll sticky-top"
                    aria-labelledby="accordion-title-one">
                      <SideList
                        infoList={[
                            { element: "Servizi Online", id: "#latest-posts"},
                            { element: "Pagamenti", id: "#payments" },
                        ]}/>
                  </div>
                </div>
                <div className="col-12 col-lg-8 offset-lg-1">
                  <div
                    className="it-page-section mb-40 mb-lg-60"
                    id="latest-posts"
                  >
                    <div className="cmp-card">
                      <div className="card">
                        <div className="card-header border-0 p-0 mb-lg-30 m-0">
                          <div className="d-flex">
                            <h2 className="title-xxlarge mb-3">
                              Servizi online
                            </h2>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          {
                            header.services?.map(
                              ({ id, path, title, description }) => (
                                <div className="card shadow-sm px-4 pt-4 pb-4">
                                  <span className="visually-hidden">
                                    Categoria:
                                  </span>
                                  <div className="card-header border-0 p-0 m-0">
                                    <p className="date-xsmall"></p>
                                  </div>
                                  <div className="card-body p-0 my-2">
                                    <h3 className="title-small-semi-bold t-primary m-0 mb-1">
                                      <a
                                        href={path}
                                        className="text-decoration-none"
                                      >
                                        {title}
                                      </a>
                                    </h3>
                                    <p className="text-paragraph text-truncate">
                                      {description}
                                    </p>
                                  </div>
                                </div>
                              ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
      default:
        return <></>
    }
  }

  //setting up localStorage conf (once) when the component mounts?
  useEffect(() => {
    console.log("area personale");

    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }

    if (localStorage.getItem("user")) {
      const stored = JSON.parse(localStorage.getItem("user"));
      setUser(stored);
      setCurrent(JSON.parse(stored.data));
      setFiscalCcode(stored.fiscal_code);
    }

     ServiceRequestAPI.getRequestByUser(false).then((request) => {
      console.log(request);
      if (request.success) {
        console.log(request.data); 
      }
    });
   }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <WorkPath workPathInfo={{
            base: ["Home","https://stellanello.nemea.cloud/"],
            end: "Area Personale"
          }}/>
          <div className="col-12 col-lg-10">
            <CompactInfo title={`${current?.name} ${current?.familyName}`} description={`CF: ${fiscal_code}`}/>
          </div>
          <TabHeader tabList={["Scrivania", "Messaggi","Attivita", "Servizi"]} tabSetter={tabSetter}/>
        </div>

        <div className="it-page-sections-container">
          <div className="tab-content">
            <div className="row">  
              {
                tabShower()
              }
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
