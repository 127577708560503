import React from "react";
import ReminderBox from "./ReminderBox";


function ReminderTable(props) {
    return (
        <div className="it-page-section mb-40 mb-lg-60" id="latest-posts">
            <div className="cmp-card">
                <div className="card">
                <div className="card-header border-0 p-0 mb-lg-30 m-0">
                    <div className="d-flex">
                    <h2 className="title-xxlarge mb-3">
                        {props.title}
                    </h2>
                    </div>
                </div>
                <div className="card-body p-0">
                    {/* TODO: reminders got to be dynamically created from api data */}
                    <ReminderBox date="05/04/2022" link="#" isDoc={props.isDoc}
                    serviceName="Richiesta servizio mensa scolastica" description="La richiesta AN4059281 è stata approv..."/>
                    <ReminderBox date="15/02/2022" link="#" isDoc={props.isDoc}
                    serviceName="Iscrizione Scuola Infanzia" description="La richiesta AN4059281 è stata approv..."/>
                    <button type="button" className="btn btn-xs btn-me btn-label t-primary px-0">
                    <span className="">Vedi altri messaggi</span>
                    </button>
                </div>
                </div>
            </div>
        </div>
    )
}


export default ReminderTable;