import React,{useState} from "react";
import StepHeader from "./StepHeader";
import BackForwardButton from "./ui-el/BackForwardButton";
import SavingForm from "./SavingForm";
import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import { useSelector } from "react-redux";

function DynamicStepContent(props) {
    const ctx = useSelector(state => state.stepSlice.data);

    const [step, setStep] = useState({
        currentStep: 0,
      });

    const onNext = () => {
        setStep(prev => {
            return {
                currentStep: prev.currentStep + 1
            } 
        });
    }

    const onBack = () => {
        setStep(prev => {
            return {
                currentStep:  prev.currentStep > 0 ? prev.currentStep - 1 : prev.currentStep
            } 
        });
    }

    const saveRequest = async () => {
        console.log("save");
        try{
            const res = await ServiceRequestAPI.sendServiceRequest(
                { service_id: "RMS", status: "Bozza", data: ctx },
                false
              );
            
          if(!res.success) return;              
        }catch(err) {
          console.log(err);
        }
    }

    const send = async () => {
        console.log("send");
        try{
            const res = await ServiceRequestAPI.sendServiceRequest(
                { service_id: "RMS", status: "Inviato", data: ctx },
                false
              );
            
          if(!res.success) return;  
          
          console.log("res", res);
        }catch(err) {
          console.log(err);
        }
    }

    return (
        <>
            {/* The header notices the user by changing if the page got submitted  stepList={props.stepList}*/}
            <StepHeader step={step?.currentStep} stepList={props.stepList}/>

            {
                props.steps.map((itm,idx) => {
                    if(step.currentStep === idx) {
                        if(typeof itm.component !== "function")
                            return itm.component;
                            
                        if(itm?.info)
                            return <itm.component info={itm.info} key={Math.random() * 31 * Math.random() * 11}/>
                        else
                            return <itm.component info={itm.info} key={Math.random() * 31 * Math.random() * 11}/>
                    }
                })
            }
            <SavingForm saveRequest={saveRequest} send={send} step={step.currentStep} totalSteps={props.steps.length}/>

            {/* the button on the bottom of the page when we can go forward or backwards */}
            <BackForwardButton onNext={onNext} onBack={onBack} step={step.currentStep} totalSteps={props.steps.length} />
        </>
    )
}


export default DynamicStepContent;