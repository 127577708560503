import React from "react";

import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";


function DocumentEditPopup(props) {
    return (
        <PopupBox close={props.close}>
            <PopupTextField fieldType="file" fieldTitle="Carta Di Identita"/>
            <PopupTextField fieldType="file" fieldTitle="Modulo Condizione Lavorativaq"/>
        </PopupBox>
    )
}

export default DocumentEditPopup;