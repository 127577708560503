import React from "react";


function AreaFormBox(props) {
    return (
        <section className="it-page-section" id="school-choice">
            <div className="cmp-card mb-40">
            <div className="card has-bkg-grey shadow-sm p-big p-4">
                <div className="card-header border-0 p-0 mb-lg-30">
                <div className="d-flex">
                    <h2 className="title-xxlarge mb-0 icon-required">
                    {props.title}
                    </h2>
                </div>
                <p className="subtitle-small mb-0">
                    {props.description}
                </p>
                </div>
                <textarea></textarea>
                <p style={{fontSize: "13px", marginLeft: "10px"}}>Inserire massimo 200 caratteri</p>
                {props.children}
            </div>
            </div>
        </section>
    )
}

export default AreaFormBox;