import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy"; 
import PagamentiIMUDatiPersonali from "../_Partials/PagamentiIMUDatiPersonali";
import PagamentiIMURiepilogo from "../_Partials/PagamentiIMURiepilogo";
import PagamentiIMUAnteprimaF24 from "../_Partials/PagamentiIMUAnteprimaF24";
import PagamentiIMUPreferenze from "../_Partials/PagamentiIMUPreferenze";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import DynamicStepContent from "../../UI-component/DynamicStepContent";

export default function PagamentoIMU(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);
 
  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      {/* primry work path in the page ex: Home/ services/ payments */}
      <WorkPath workPathInfo={{
        base: ["Home","https://stellanello.nemea.cloud/"],
        medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
        end: "Pagamenti"
      }}/>

      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            {/* header and page description */}
            <CompactInfo title={"Pagamento IMU"} description={`
                Inserisci le informazioni necessarie per pagare l’IMU.<br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`}/>
          </div>
          
          {/* dinamically creates the pages giving them step properties */}
          <DynamicStepContent stepList={["INFORMATIVA SULLA PRIVACY","DATI GENERALI","PREFERENZE DI SERVIZIO","RIEPILOGO"]} steps={[
            {component:Privacy,info: {user,description: `Il gestisce i dati personali forniti e
              liberamente comunicati sulla base dell’articolo 13 del
              Regolamento (UE) 2016/679 General data protection regulation
              (Gdpr) e degli articoli 13 e successive modifiche e
              integrazione del decreto legislativo (di seguito d.lgs)
              267/2000 (Testo unico enti locali). `}},
            {component:PagamentiIMUDatiPersonali,info: user},
            {component:PagamentiIMURiepilogo,info: user},
            {component:PagamentiIMUPreferenze,info: user},
            {component:PagamentiIMUAnteprimaF24,info: user},
          ]}/>
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
