import { useEffect } from 'react';
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SpidAPI } from '../Apis/SpidAPI'

 

export default function Loading () {
  const navigate = useNavigate();

  const[resHtml, setResHtml] = useState('')
  let params = useParams()

  useEffect(() => {
    let abortController

    (async () => {
      abortController = new AbortController()
      let signal = abortController.signal
      console.log("ciao");
      try {
        if (params.idp) {
          console.log("ciao 1");
          abortController = new AbortController()
          let signal = abortController.signal

          if (params.idp) {
            const res = await SpidAPI.SPIDlogin(params.idp, signal)
            console.log(res)

            setResHtml(Base64.decode(res.data));

            setTimeout(() => {
              document.forms['my-form'].submit()
            }, 1000)
          }
        } else {
          console.log(localStorage);
          if (localStorage.getItem('destination')) {
            console.log("ciao 3");
            let redirect = localStorage.getItem('destination')
            const res = await SpidAPI.SPIDAuthentication(params.token, signal)

            console.log(res)
            const data = res.data
            const token = data.access_token

            if (!token) {
              alert('Unable to login. Please try after some time.')
              return
            }

            localStorage.removeItem('access_token')
            localStorage.setItem('access_token', token)
            localStorage.setItem('user', JSON.stringify(data.user))
            navigate(redirect)
          }
        }
      } catch (err) {
        console.log(err)
      }
    })()
    return () => abortController.abort()
  }, [])

  return (
    <>
        <React.Fragment>
          <div dangerouslySetInnerHTML={{ __html: resHtml }} id='redirect'></div>
          <>
            <section class='main-wrapper u-layout u-layout-wide u-layoutCenter'>
              <header class='main-header u-padding-all-xxl'>
                <div class='main-header__inner Grid Grid--fill Grid--withGutter Grid--equalHeight Grid--alignMiddle'>
                  <div class='u-size1of2'>
                  </div>
                  <div class='u-size1of2'>
                    <div class='u-textRight'>
                      <span class='u-text-r-xs u-color-grey-40 u-padding-all-xs u-border-all-xxs'></span>
                    </div>
                  </div>
                </div>
              </header>
              <main class='main u-layout u-layout-extrasmall u-layoutCenter '>
                <article class='main-bodytext u-padding-all-xl'>
                  <div class='u-textCenter'>
                    <img
                      src='../assets/images/loading.svg'
                      class='icon-error'
                      onerror="this.src='../assets/images/loading.png'; this.onerror=null;"
                      alt='SPID Logo'
                     style={{margin:'0px auto', justifyContent:'center',textAlign:'center', transform:'translateX(-50%)',position:'absolute',top:'30%',left:'50%'}}
                    />
                  </div>
                  <p class='u-text-r-xs u-color-grey-40 u-textWeight-400 u-margin-top-l u-lineHeight-l u-textCenter'
                    style={{margin:'0px auto', justifyContent:'center',textAlign:'center', transform:'translateX(-50%)',position:'absolute',top:'65%',left:'50%'}}>
                    Ti stiamo trasferendo al servizio da te richiesto
                  </p>
                </article>
              </main>
            </section>
          </>
        </React.Fragment>
    </>
  )
}