import React from "react";

function FormSlider(props) {
    return (
        <section className="it-page-section" id={props.id}>      
        <div className="cmp-card mb-40">
            <div className="card has-bkg-grey shadow-sm">
                <div className="card-header border-0 p-0 mb-lg-30">
                    <div className="d-flex">
                        <h2 className="title-xxlarge mb-1">Coobbligato</h2>
                    </div>
                    <p className="subtitle-small mb-0">Seleziona o aggiungi il coobbligato per il quale pagherai l’imposta</p>
                </div>
                <div className="card-body p-0">
                    <div className="cmp-toggle">
                        <div className="toggles">
                        <label className="label mb-0" htmlFor="toggle-toggle">
                            C’è un coobbligato
                            <input type="checkbox" id="toggle-toggle" />
                            <span className="lever me-0 bg-white" />
                        </label>
                        </div>
                    </div>
                    {/* <div className="infoWrapper d-none">
                        <div className="select-wrapper mt-4 mb-3 mb-lg-30 mt-lg-40 bg-transparent p-0">
                            <label htmlFor="toggle" className>Tipologia*</label>
                            <select id="toggle" className="bg-transparent" required>
                                <option selected="selected" value>Seleziona opzione</option>
                                <option value="Persona fisica">Persona fisica</option>
                                <option value="Persona fisica">Persona fisica</option>
                                <option value="Persona fisica">Persona fisica</option>
                                <option value="Persona fisica">Persona fisica</option>
                            </select>
                        </div>
                        <div className="cmp-info-button-card radio-card">
                        <div className="card p-3 p-lg-4">
                            <div className="card-header mb-0 p-0">
                            <div className="form-check m-0">
                                <input className="radio-input" name="beneficiaries" type="radio" id="third" />
                                <label htmlFor="third">
                                <h3 className="big-title mb-0">Giovanni Rossi</h3>
                                </label>
                            </div>
                            </div>
                            <div className="card-body p-0">
                            <p className="card-info">Codice Fiscale <br /> <span>GVNRSS72H25H501Y</span></p>
                            <div className="accordion-item">
                                <div className="accordion-header" id="heading-collapse-benef-3">
                                <button className="collapsed accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-benef-3" aria-expanded="false" aria-controls="collapse-benef-3">
                                    <span className="d-flex align-items-center">
                                    Mostra tutto
                                    <svg className="icon icon-primary icon-sm">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                                    </svg>
                                    </span>
                                </button>
                                </div>
                                <div id="collapse-benef-3" className="accordion-collapse collapse" role="region">
                                <div className="accordion-body p-0">
                                    <div className="cmp-info-summary bg-white has-border">
                                    <div className="card">
                                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                        <h4 className="title-large-semi-bold mb-3">Anagrafica</h4>
                                        <a href="#" className="text-decoration-none"><span className="text-button-sm-semi t-primary">Modifica</span></a>
                                        </div>
                                        <div className="card-body p-0">
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Codice Fiscale</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                GLABNC72H25H501Y
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Data di nascita</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                02/03/1975
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Luogo di nascita</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Roma
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Sesso</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Femmina
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Nazionalità</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Italiana
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Professione</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Impiegata
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Scolarità</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Laurea Magistrale
                                            </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card-footer p-0">
                                        </div>
                                    </div>
                                    </div>      <div className="cmp-info-summary bg-white has-border">
                                    <div className="card">
                                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                        <h4 className="title-large-semi-bold mb-3">Indirizzi</h4>
                                        <a href="#" className="text-decoration-none"><span className="text-button-sm-semi t-primary">Modifica</span></a>
                                        </div>
                                        <div className="card-body p-0">
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Residenza</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Via Roma 16, 00100 Roma, IT
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Domicilio</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                Piazza Risorgimento 16, 00100 Roma, It
                                            </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card-footer p-0">
                                        </div>
                                    </div>
                                    </div>      <div className="cmp-info-summary bg-white has-border">
                                    <div className="card">
                                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                        <h4 className="title-large-semi-bold mb-3">Contatti</h4>
                                        <a href="#" className="text-decoration-none"><span className="text-button-sm-semi t-primary">Modifica</span></a>
                                        </div>
                                        <div className="card-body p-0">
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Telefono</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                +39 331 1234567
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Email</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                giulia.bianchi@gmail.com
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Recapito postale</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                P.zza Risorgimento 16, 00100 Roma
                                            </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card-footer p-0">
                                        </div>
                                    </div>
                                    </div>      <div className="cmp-info-summary bg-white has-border">
                                    <div className="card">
                                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                        <h4 className="title-large-semi-bold mb-3">Documenti</h4>
                                        <a href="#" className="text-decoration-none"><span className="text-button-sm-semi t-primary">Modifica</span></a>
                                        </div>
                                        <div className="card-body p-0">
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Carta d'identità</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                giulia_bianchi_carta_di_identità_fronte_retro.pdf
                                            </p>
                                            <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                                <span className="d-flex align-items-center">
                                                <svg className="icon icon-sm icon-success" aria-hidden="true">
                                                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                                </svg>
                                                Documento inserito con successo
                                                </span>
                                            </p>
                                            </div>
                                        </div>
                                        <div className="single-line-info border-light">
                                            <div className="text-paragraph-small">Modulo condizione lavorativa</div>
                                            <div className="border-light">
                                            <p className="data-text">
                                                giulia_bianchi_modulo.pdf
                                            </p>
                                            <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                                <span className="d-flex align-items-center">
                                                <svg className="icon icon-sm icon-success" aria-hidden="true">
                                                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                                </svg>
                                                Documento inserito con successo
                                                </span>
                                            </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card-footer p-0">
                                        </div>
                                    </div>
                                    </div>  </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

        </section>
    )
}

export default FormSlider;