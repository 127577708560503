import React from "react";
import CustomerRegistry from "./CustomerRegistry";
import stepSlice from "../../context/StepsContext";
import { useDispatch, useSelector } from "react-redux";

function CustomerCard(props) {
    const dispatcher = useDispatch();
    const data = useSelector(state => state.stepSlice?.data); 

    const onChoice = (e) => {
        console.log(e.target.getAttribute("data-taxID"));
        dispatcher(stepSlice.actions.changeSelected({id: props?.title, taxID: e.target.getAttribute("data-taxID") }))
    }


    const typeChecker = (type) => {
        let internal = "";
        switch(type) {
            case "Richiedente": 
                internal = "applicant";
                break;
            case "Beneficiario": 
                internal = "beneficiary";
                break;
            default: break;
        }

        return internal;
    }

    return ( 
        <>
            {
                props.info &&
                    props.info.map((itm,y) => {
                        const idx = parseInt((Math.random() * 31 * Math.random() * 11));
                        
                        return (
                            <div className="cmp-info-button-card radio-card" key={Math.random * 31}>
                                <div className="card p-3 p-lg-4">
                                <div className="card-header mb-0 p-0">
                                    <div className="form-check m-0">
                                    {
                                            data?.[typeChecker(props?.title)][y]?.selected ?
                                            <>
                                                <input checked="checked" onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm?.reg?.CodiceFiscale}/>
                                                <label htmlFor={"a" + idx}>
                                                <h3 className="big-title mb-0">{itm?.reg?.Nome} {itm?.reg?.Cognome}</h3>
                                                </label> 
                                            </>:
                                            <>
                                                <input onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm?.reg?.CodiceFiscale}/>
                                                <label htmlFor={"a" + idx}>
                                                <h3 className="big-title mb-0">{itm?.reg?.Nome} {itm?.reg?.Cognome}</h3>
                                                </label>
                                            </>
                                    }
                                    </div>
                                </div>
                                    <div className="card-body p-0">
                                        <p className="card-info">Codice Fiscale <br /> <span>{itm?.reg?.CodiceFiscale}</span></p>
                                        <CustomerRegistry registryData={itm} key={Math.random() * 31 * Math.random() * 11} id={props.title} taxID={itm?.reg?.CodiceFiscale}/>
                                    </div>
                                </div>
                            </div>
                        )})
            }
        
        </>
  )
}

export default CustomerCard;