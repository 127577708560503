import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopupBox from "./PopupBox";
import PopupTextField from "./ui-el/PopupTextField";
import PopupSelectField from "./ui-el/PopupSelectField";

import stepSlice from "../context/StepsContext";


function PreferenceRegistryPopup(props) {
    const dispatcher = useDispatch();
    
    const preferencesCtx = useSelector(state => state.stepSlice.data.preferences);
   
    const [data,setData] = useState({
        "scuola":"",
        "tariffa_esonero":"",
        "tariffa_riduzione": "",
        "frequenza": "",
        "dieta_diversificata":[],
    });

    const edit = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: "Preferenze",data: data}));
        props.close();
    }

    useEffect(() => {
        setData(preferencesCtx);
    },[])

    const onDataChange = (e) => {
        const id = e.target.id;
        setData(prev => {
            let modified = {...prev};
            
            Object.keys(modified).map(key => {
                if(id === key) 
                    modified[id] = e.target.value;
            })

            return modified;
        })
    }

    const onMultiChange = (e) => {
        let modified = [];

        for(let i of e.target.options) {
            if(i.selected) 
                modified.push(i.text);
        }

        setData(prev => {
            const newObj = {...prev};
            newObj[e.target.id] = modified;

            return newObj;
        })
    }

    return (
        <PopupBox close={props.close} save={edit} title="Persona"> 
            {
                Object.keys(preferencesCtx).map(key => {
                    if(Array.isArray(preferencesCtx[key])) {
                        //options must be dymamic
                        return <PopupSelectField onChange={onMultiChange} multi={true} id={key} fieldTitle={key} options={["In sviluppo", "In sviluppo", "In sviluppo"]}
                          description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"/>
                    }
                    else
                        return <PopupTextField defaultValue={preferencesCtx[key]} fieldType="text" fieldTitle={key} onChange={onDataChange}/>
                })
            }
        </PopupBox>
    )
}

export default PreferenceRegistryPopup;