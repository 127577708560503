import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import ServiziPagamentoDatiPersonali from "../_Partials/ServiziPagamentoDatiPersonali";
import ServiziPagamentoDatiSpecifici from "../_Partials/ServiziPagamentoDatiSpecifici";
import ServiziPagamentoRiepilogo from "../_Partials/ServiziPagamentoRiepilogo";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import DynamicStepContent from "../../UI-component/DynamicStepContent";

export default function TrasportoScolastico(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
          end: "Servizi scolastici"
        }}/>
      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <CompactInfo title={"Richiesta servizio trasporto scolastico"} description={`
                Inserisci le informazioni necessarie per richiedere il servizio
                di trasporto scolastico. <br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`}/>
          </div>
          <DynamicStepContent stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI","RIEPILOGO","ANTEPRIMA F24"]} steps={[
            {component:Privacy,info: header?.description},
            {component:ServiziPagamentoDatiPersonali,info: user},
            {component:ServiziPagamentoDatiSpecifici,info: user},
            {component:ServiziPagamentoRiepilogo,info: user},
          ]}/>
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
