import { apiAuth } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const CategoryAPI = {

    getCategory: async function (cancel = false) {
        const response = await apiAuth.request({
          url: "/category/",
          method: "GET",
          signal: cancel ? cancelApiObject[this.getCategory.name].handleRequestCancellation().signal : undefined,
        })  
        return response.data;
      }
 
}

// defining the cancel API object for CategoryAPI
const cancelApiObject = defineCancelApiObject(CategoryAPI)