import React from "react";


function CustomerCardHeader(props) {
    //formtitle, description,hasType,options
    return (
        <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
                <h2 className="title-xxlarge mb-1">{props.title}</h2>
            </div>
            <p className="subtitle-small mb-0">{props.description}</p>
            <br></br>
            {
                props.type &&
                    <div className="select-wrapper mt-40 mb-3 mb-lg-30 bg-transparent p-0">
                        <label htmlFor="taxpayer-type" className>Tipologia*</label>
                        <select id="taxpayer-type" className="bg-transparent" required>
                            <option selected="selected" value>Seleziona opzione</option>
                            <option value="Persona fisica">Persona fisica</option>
                            <option value="Persona fisica">Persona fisica</option>
                            <option value="Persona fisica">Persona fisica</option>
                            <option value="Persona fisica">Persona fisica</option>
                        </select>
                    </div>
            }
        </div>
    )
}


export default CustomerCardHeader;