import React, { useState } from "react";

import CardContainer from "../registryForms/CardContainer";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import PropertyEditPopup from "../editForms/PropertyEditPopup";
import RegistryEditPopup from "../editForms/RegistryEditPopup";
import PreferenceRegistryPopup from "../PreferenceRegistryPopup";

function SummaryCard(props) {
    const [edit,showEdit] = useState(false);

    const close = () => {
        showEdit(false);
    }
    
    const open = () => {
        showEdit(true);
    }

    const SummaryTypeShower = () =>  {
        switch(props.type) {
            case "Preferenze":
                return <PreferenceRegistryPopup  close={close}/>
            case "Richiedente":
                return <RegistryEditPopup close={close} id={"Richiedente"} taxID={props?.taxID}/>
            case "Beneficiario":
                return <RegistryEditPopup close={close} id={"Beneficiario"} taxID={props?.taxID}/>
            case "Contribuenti":
                return  (
                    <PopupBox close={close} title="Contribuente"> 
                        <PopupTextField fieldType="text" fieldTitle="Codice Fiscale"/>
                        <PopupTextField fieldType="text" fieldTitle="Data Di Nascita"/>
                        <PopupTextField fieldType="text" fieldTitle="Luogo Di Nascita"/>
                        <PopupTextField fieldType="text" fieldTitle="Sesso"/>
                    </PopupBox>
                ) 
            case "Coobligato": 
                return  (
                    <PopupBox close={close} title="Coobligato"> 
                        <PopupTextField fieldType="text" fieldTitle="Codice Fiscale"/>
                        <PopupTextField fieldType="text" fieldTitle="Data Di Nascita"/>
                        <PopupTextField fieldType="text" fieldTitle="Luogo Di Nascita"/>
                        <PopupTextField fieldType="text" fieldTitle="Sesso"/>
                    </PopupBox>
                ) 
            case "Immobili": 
            return (
                <PropertyEditPopup close={close}/>
            )
            default: 
        }
    }

    return (
        <CardContainer title={props.type}>
            <div className="card-body p-0">
                <div className="cmp-info-summary bg-white mb-4 mb-lg-30 p-3 p-lg-4">
                    <div className="card">
                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                            <h4 className="title-large-semi-bold mb-3">{props.cardTitle}</h4>
                            {
                                props.mod && 
                                    <a className="text-decoration-none">
                                        <span className="text-button-sm-semi t-primary" onClick={open}>
                                            Modifica
                                        </span>
                                    </a>
                            }
                        </div>
                        <div className="card-body p-0">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            {
                edit &&
                    SummaryTypeShower()
            }
        </CardContainer>
    )
}

export default SummaryCard;