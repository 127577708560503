import React from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";

//OFFICE SELECTOR
function Fifth(props) {
    return (
        <React.Fragment>
            <SideList infoList={[{element: "Ufficcio", id: "#office" }]}/>
            
            {/* TODO: Summary card created dynamially using api data */}

            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <SummaryCard cardTitle="Ufficio" type="Riepilogo">
                    <FormBoxField title="Tipologia ufficio" value="from api" />
                    <FormBoxField title="Municipalita" value="from api" />
                </SummaryCard>

                <SummaryCard cardTitle="Data e orario" >
                    <FormBoxField title="Data" value="from api" />
                    <FormBoxField title="Ora" value="from api" />
                </SummaryCard>
                
                <SummaryCard cardTitle="Dettagli Appuntamento" >
                    <FormBoxField title="Motivo" value="from api" />
                    <FormBoxField title="Dettagli" value="from api" />
                </SummaryCard>
                
                <SummaryCard cardTitle="Richiedente">
                    <FormBoxField title="Nome" value="from api" />
                    <FormBoxField title="Email" value="from api" />
                    <FormBoxField title="Cognome" value="from api" />
                </SummaryCard>
            </section>
        </React.Fragment>
    )
}


export default Fifth;