import React from "react";
import {useSelector} from "react-redux";

function BackForwardButton(props){  
    const pvtCtx = useSelector(state => state.stepSlice.privacy);

    return (
        <div className="cmp-nav-steps">
            <nav className="steppers-nav" aria-label="Step">
            {
                props.step > 0 &&
                    <button style={{float: "right", transform: "translateY(-100px)"}} onClick={props.onBack} 
                        type="button" className="btn btn-sm steppers-btn-prev p-0">
                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-chevron-left" />
                        </svg>
                        <span className="text-button-sm t-primary">Indietro</span>
                    </button>
            }
            {
                props.step < props.totalSteps -1 &&
                    <button
                        style={{float: "right", transform: "translateY(-100px)"}}
                        id="step1Next"
                        type="submit"
                        className={`btn btn-primary btn-sm steppers-btn-confirm btn-next-step ${!pvtCtx ? "disabled-btn-pvt" : ""}`}
                        data-bs-validate="validate"
                        onClick={props.onNext}>
                        <span className="text-button-sm">Avanti</span>
                        <svg className="icon icon-white icon-sm" aria-hidden="true">
                        <use href="#it-chevron-right"></use>
                        </svg>
                    </button>
            }
            
  
            </nav>
        </div>
    )
}


export default BackForwardButton;