import React from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import IseeFormBox from "../../UI-component/registryForms/IseeFormBox";
import SideList from "../../UI-component/SideList";
import ErrorAlert from "../../UI-component/ErrorAlert";
import { useSelector } from "react-redux";

export default function ServiziPagamentoDatiPersonali(props) {
  const dataCtx = useSelector(state => state.stepSlice.data);

  // dynamically renders the box checking if 
  //any data resides in the context, if data 
  //is present in the context it is gonna use that.
  // it is gonna be swapping between api data and context data
  const dyanmicRenderer = (itm) => {
    switch(itm.type) {
      case "Person":
        return(
          <CustomerFormBox key={Math.random() * 31 * Math.random() * 11}
            id={itm?.title}
            cards={dynamicData(itm?.title)}
            description="Informazioni su di te"
            type={false}
            title={itm?.title}
          />
        )
      case "Isee": 
        return (
          <IseeFormBox id={itm?.title} cards={dynamicData(itm?.title)} key={Math.random() * 31 * Math.random() * 11}/>
        )
      default: 
        return <></>;
    }
  }

  // Dynamically checks the key of the given object
  // and return the context object filtered by the title
  const dynamicData = (title) => {  
    let modTitle = "";

    switch(title){
      case "Richiedente":
        modTitle = "applicant";
        break;
      case "Beneficiario":
        modTitle = "beneficiary";
        break;
      case "ISEE":
        modTitle = "isee";
        break;
      default: break;
    }  

    return Object.keys(dataCtx).map(key => {
      if(dataCtx[key] !== undefined && dataCtx[key] !== [] ) {
        if(modTitle === key) {
          if(Array.isArray(dataCtx[key])) {
            return dataCtx[key].map(itm => {
              return itm;
            })
          }
          else
            return dataCtx[key];
        }
      }
    })
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#applicant-info" },
          { element: "benediciario", id: "#beneficiary-info" },
          { element: "ISEE", id: "#isee-info" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">

        {/* error alert */}
        <ErrorAlert errors={[{ name: "ISEE", id: "#isee-info" }]} />

        <div className="it-page-sections-container">
          {
            props.info.components.map(itm => {
              return dyanmicRenderer(itm);
            })
          }
        </div>
      </div>
    </div>
  );
}
