import React from "react";


function CompactInfo(props) {
    const innerHtml = props.description;

    return (
        <div className="cmp-heading pb-3 pb-lg-4">
            <h1 className="title-xxxlarge">{props.title}</h1>
             <p className="subtitle-small" dangerouslySetInnerHTML={{ __html: innerHtml }} />
            <p className="subtitle-small mt-lg-2 mb-4">
            Hai bisogno di assistenza?{" "}
            <a className="title-small-semi-bold t-primary" href="#">Contattaci</a>
            </p>
      </div>
    )
}

export default CompactInfo